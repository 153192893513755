import { action, computed, makeObservable, observable, when } from 'mobx';
import {
  accountRecoveryByPhoneSaveNewPasswordClick,
  accountRecoveryByPhoneSelectNewPasswordModalLoad,
  signUpPasswordStrengthIndicator,
} from '@wix/bi-logger-hls2/v2';
import { Constraint, ERROR_TRANSLATION_KEYS } from '../utils/validators';
import { RootStore } from './root';
import { ROUTES } from '../routes';
import {
  ACCOUNT_SETTINGS_URL,
  SEARCH_PARAMS,
  WIX_HOME_URL,
} from '../utils/constants';
import { extractErrorKeyByErrorCode } from '../utils/errorHandler';
import { PasswordFormField } from './passwordFormField';
import { PasswordPolicy } from '@wix/ambassador-iam-password-v1-password/types';

export class ResetPasswordStore {
  public passwordField: PasswordFormField;
  public confirmPasswordField: PasswordFormField;
  public emailToken: string;
  public isResetPasswordComplete: boolean = false;
  private readonly rootStore: RootStore;
  private urlParams = new URLSearchParams(window.location.search);
  public passwordPolicy?: PasswordPolicy;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.passwordField = new PasswordFormField(
      '',
      undefined,
      this.rootStore.managePasswordStore.validateNewPassword
    );
    const passwordMatchRule: Constraint = [
      (str: string) => str === this.passwordField.value,
      this.rootStore.i18n.t(ERROR_TRANSLATION_KEYS.PASSWORDS_NOT_MATCH),
    ];
    this.confirmPasswordField = new PasswordFormField('', [passwordMatchRule]);
    this.setEmailToken();
    makeObservable(this, {
      passwordField: observable,
      confirmPasswordField: observable,
      emailToken: observable,
      isResetPasswordComplete: observable,
      continueToConfirmationScreen: action.bound,
      updatePasswordFieldMatchError: action.bound,
      onResetPasswordConfirmationSubmit: action.bound,
      submit: action.bound,
      setEmailToken: action.bound,
      enableAccess: computed,
      enableAccessConfirmation: computed,
      passwordPolicy: observable,
    });
    when(
      () => !!this.rootStore.resetPasswordInvalidStore,
      this.onLoad.bind(this)
    );
    this.fetchResetPasswordPolicy();
  }

  public fetchResetPasswordPolicy = async () => {
    if (this.emailToken) {
      this.passwordPolicy =
        await this.rootStore.passwordService.getPasswordPolicyFromResetToken(
          this.emailToken
        );
    }
  };

  private onLoad() {
    const { resetPasswordInvalidStore, biLogger, navigationStore } =
      this.rootStore;
    if (this.isRecoveryWithCode()) {
      biLogger.report(accountRecoveryByPhoneSelectNewPasswordModalLoad({}));
    }
    if (
      navigationStore.loginDialogContext === 'reset-password' &&
      this.enableAccess &&
      navigationStore.currentRoute !== ROUTES.RESET_PASSWORD &&
      !resetPasswordInvalidStore.isInvalid
    ) {
      return navigationStore.navigate(ROUTES.RESET_PASSWORD);
    }

    if (
      resetPasswordInvalidStore.isInvalid &&
      (navigationStore.currentRoute === ROUTES.RESET_PASSWORD ||
        navigationStore.loginDialogContext === 'reset-password')
    ) {
      if (resetPasswordInvalidStore.isBadToken) {
        return navigationStore.redirect(this.getForgotPasswordUrl(), {
          keepRelative: true,
        });
      }
      navigationStore.navigate(ROUTES.RESET_PASSWORD_INVALID);
    }
  }

  public async onResendExpired() {
    try {
      await this.rootStore.apiStore.getCurrentPage();
    } catch (_) {}
  }

  public setEmailToken(emailToken?: string) {
    this.emailToken =
      emailToken ??
      this.rootStore.navigationStore.getQueryParam(SEARCH_PARAMS.EMAIL_TOKEN);
  }

  public hasError(): boolean {
    return !!(this.passwordField.errorInfo || this.passwordField.serverError);
  }

  public async submit() {
    if (this.passwordField.errorInfo || this.passwordField.serverError) {
      return;
    }
    try {
      this.rootStore.biLogger.report(
        accountRecoveryByPhoneSaveNewPasswordClick({})
      );
      const res = await this.rootStore.apiStore.resetPassword({
        newPassword: this.confirmPasswordField.value,
        emailToken: this.emailToken,
      });
      if (!res.success) {
        const errorCode = res.errorCode.toString();
        this.passwordField.addError(
          this.rootStore.i18n.t(extractErrorKeyByErrorCode(errorCode))
        );
        return;
      }
      this.rootStore.biLogger.report(
        signUpPasswordStrengthIndicator({
          strength: this.rootStore.passwordStrengthExtractor
            .getPasswordStrengthForBiEvent(this.confirmPasswordField.value)
            .toString(),
          origin: 'reset-password',
        })
      );
      return this.continueToConfirmationScreen();
    } catch (error: any) {
      // TODO handle error
      throw error?.errorCode ?? error?.response?.data?.errorCode;
    }
  }

  get enableAccess(): boolean {
    return (
      !!this.emailToken || this.urlParams.get('debugCase') === 'resetPassword'
    );
  }

  get enableAccessConfirmation(): boolean {
    return (
      this.isResetPasswordComplete ||
      this.urlParams.get('debugCase') === 'resetPasswordConfirmation'
    );
  }

  public isRecoveryWithCode() {
    return !this.rootStore.navigationStore.getQueryParam(
      SEARCH_PARAMS.EMAIL_TOKEN
    );
  }

  public getForgotPasswordUrl() {
    const { navigationStore, sessionId } = this.rootStore;
    const overrideLocale = navigationStore.getQueryParam('overrideLocale');
    const forgotPasswordParams = new URLSearchParams({
      sessionId: sessionId.get(),
      ...(overrideLocale ? { overrideLocale } : {}),
    });
    return `/account-recovery/forgot-password?${forgotPasswordParams.toString()}`;
  }

  public isSubmittable(): boolean {
    return !!(
      this.passwordField.isValid &&
      this.confirmPasswordField.isValid &&
      this.passwordField.value === this.confirmPasswordField.value
    );
  }

  public onResetPasswordConfirmationSubmit() {
    if (this.isRecoveryWithCode()) {
      return this.rootStore.navigationStore.redirect(ACCOUNT_SETTINGS_URL);
    }
    this.rootStore.navigationStore.navigateToLogin();
  }

  public continueToConfirmationScreen() {
    this.isResetPasswordComplete = true;
    when(
      () => this.isResetPasswordComplete,
      () =>
        this.rootStore.navigationStore.navigate(
          ROUTES.RESET_PASSWORD_CONFIRMATION
        )
    );
  }

  public updatePasswordFieldMatchError() {
    if (
      this.passwordField.value !== this.confirmPasswordField.value &&
      this.confirmPasswordField.isTouched
    ) {
      !this.confirmPasswordField.errors &&
        this.confirmPasswordField.addError(
          this.rootStore.i18n.t('errors.signup.passwords.doNotMatch')
        );
    } else {
      this.confirmPasswordField.errors = false;
    }
  }
}
