import { action, makeObservable, observable, when } from 'mobx';
import { ERROR_CODES, extractServerErrorCode } from '../utils/errorHandler';
import { AuthenticateByCodeStore } from './authenticateByCode';
import { RootStore } from './root';
import {
  accountRecoveryByPhoneVerifyCodeClick,
  accountRecoveryByPhoneCodeVerifiedSuccessfully,
  accountRecoveryByPhoneCodeVerificationFailed,
  accountRecoveryByPhoneResendCodeClick,
  accountRecoveryByPhoneLostPhoneAccessClick,
} from '@wix/bi-logger-hls2/v2';
import {
  EXPERIMENTS,
  LOST_ACCESS_TO_PHONE_ARTICLE_URL,
  TwoFactorAuthMethods,
} from '../utils/constants';
import { ROUTES } from '../routes';

export class AccountRecoveryCodeStore extends AuthenticateByCodeStore {
  readonly allowRememberMe: boolean = false;
  readonly dialogTitleKey: string = 'enter_code.title';
  public accountRecoveryToken;
  private parentAccountId;
  accountEmail: string;
  private urlParams: URLSearchParams = new URLSearchParams(window.location.search)
  private isDebug : boolean = this.urlParams.get('debugCase') === 'recoverAccount';

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.SUBMIT_INTERACTION_NAME = 'verify-code-recovery';
    this.currentTwoFAMethod = TwoFactorAuthMethods.Phone;
    this.accountRecoveryToken = this.rootStore.navigationStore.getQueryParam('accountRecoveryToken');
    this.parentAccountId = this.rootStore.navigationStore.getQueryParam('parentAccountId');

    this.init();
    makeObservable(this, {
      currentTwoFAMethod: observable,
      enabledTwoFAMethods: observable,
      twoFAHint: observable,
      accountEmail: observable,
      setAuthParams: action,
      verify: action,
      resendCode: action,
      onLostAccess: action.bound,
      enableAccess: action.bound,
      init: action,
    });
  }

  async init() {
    this.setAuthParams({
      accountEmail:
        this.rootStore.navigationStore.getQueryParam('accountEmail'),
      accountRecoveryPhoneHint: this.rootStore.navigationStore.getQueryParam(
        'accountRecoveryPhoneHint',
      ),
    });
    if (!this.accountEmail || !this.twoFAHint) {
      return;
    }
    const { navigationStore } = this.rootStore;
    when(
      () => this.enableAccess(),
      () => {
        navigationStore.navigate(ROUTES.RECOVER_ACCOUNT);
      },
    );
  }

  setAuthParams({
    accountEmail,
    accountRecoveryPhoneHint,
  }: {
    accountEmail: string;
    accountRecoveryPhoneHint: string;
  }) {
    this.twoFAHint = accountRecoveryPhoneHint;
    this.accountEmail = accountEmail;
  }

  onResendCodeClicked() {
    this.rootStore.biLogger.report(accountRecoveryByPhoneResendCodeClick({}));
    return this.resendCode();
  }

  async resendCode() {
    try {
      const data = await this.rootStore.apiStore.resendRecoveryCode(
        this.accountEmail,
        this.parentAccountId !== '' ? this.parentAccountId : undefined,
      );
      this.accountRecoveryToken = data.accountRecoveryToken;
      return data.errorCode;
    } catch (error: any) {
      return extractServerErrorCode(error);
    }
  }

  async verify({ code }: { code: string }) {
    this.rootStore.biLogger.report(accountRecoveryByPhoneVerifyCodeClick({}));
    const shouldUseNewApi = this.rootStore.experiments.enabled(EXPERIMENTS.ACCOUNT_RECOVERY_NEW_API);
    let resetPasswordToken;
    try {
      if (shouldUseNewApi) {
        const verifyAccountRecoveryCodeResponse =
          await this.rootStore.apiStore.verifyRecoveryCodeNewApi({
            code,
            accountRecoveryToken: this.accountRecoveryToken,
          });
        resetPasswordToken = verifyAccountRecoveryCodeResponse.changePasswordToken;
      } else {
        const verifyRecoveryCodeResponse =
          await this.rootStore.apiStore.verifyRecoveryCode({
            email: this.accountEmail,
            code: parseInt(code, 10),
            parentAccountId: this.parentAccountId,
          });
        resetPasswordToken = verifyRecoveryCodeResponse.payload?.emailToken;
        if (!verifyRecoveryCodeResponse.success) {
          this.rootStore.biLogger.report(
            accountRecoveryByPhoneCodeVerificationFailed({}),
          );
          const errorCode = extractServerErrorCode(verifyRecoveryCodeResponse);
          this.handleError(errorCode);
          throw {errorCode};
        }
      }

      this.rootStore.biLogger.report(
        accountRecoveryByPhoneCodeVerifiedSuccessfully({}),
      );
      this.rootStore.resetPasswordStore.setEmailToken(resetPasswordToken);
      when(
        () => this.rootStore.resetPasswordStore.enableAccess,
        () => this.rootStore.navigationStore.navigate(ROUTES.RESET_PASSWORD),
      );
      return;
    } catch (error: any) {
      this.handleError(extractServerErrorCode(error));
      throw new Error(error);
    }
  }

  enableAccess() {
    return (!!(this.accountEmail && this.twoFAHint)) || this.isDebug;
  }

  onLostAccess() {
    this.rootStore.biLogger.report(
      accountRecoveryByPhoneLostPhoneAccessClick({}),
    );
    this.rootStore.navigationStore.redirect(
      LOST_ACCESS_TO_PHONE_ARTICLE_URL(this.rootStore.i18n.language),
    );
  }
}
