import React, { useContext } from 'react';
import { Box, Button } from '@wix/design-system';
import { dataHooks } from '../../dataHooks';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { DialogHeader } from '../DialogHeader/DialogHeader';
import { DialogFormContainer } from '../DialogContainer';
import { AppContextProvider } from '../AppLoader';
import s from './BlockedAccount.scss';
import { BodyContainer } from '../AuthPage/AuthPage';

interface BlockedAccountProps {
  dataHook?: string;
}

export const BlockedAccount: React.FC<BlockedAccountProps> = observer(
  ({ dataHook = dataHooks.blockedAccountDialog.container }) => {
    const {
      rootStore: {
        blockedAccountStore,
        displayStore: { preset },
      },
    } = useContext(AppContextProvider);
    const { titleKey, subtitleKeys, buttonKey } = blockedAccountStore;
    const { t } = useTranslation();
    return (
      <Box direction="vertical" width="100%" align="center">
        <DialogFormContainer
          dataHook={dataHook}
          mainHeaderProps={{
            backButtonCallback: blockedAccountStore?.onBackButtonClicked,
          }}
        >
          <BodyContainer>
            <Box direction="vertical" align="center" gap="SP1">
              <DialogHeader>
                <DialogHeader.Title flow='secondaryFlow'>{t(titleKey)}</DialogHeader.Title>
                <DialogHeader.SubTitleSmall>
                  {t(subtitleKeys[0])}
                  <br className={s.subtitleBr} />
                  {t(subtitleKeys[1])}
                </DialogHeader.SubTitleSmall>
              </DialogHeader>
              <Button
                onClick={() => blockedAccountStore?.contactCustomerCare()}
                skin={preset.buttonDesign?.skin}
                size="medium"
              >
                {t(buttonKey)}
              </Button>
            </Box>
          </BodyContainer>
        </DialogFormContainer>
      </Box>
    );
  }
);
