import { action, makeObservable, observable } from 'mobx';
import { LOGIN_PAGE_CONTEXT, SIGNUP_PAGE_CONTEXT } from '../utils/constants';
import { getCookie } from '../utils/cookie';
import { RootStore } from './root';

const NULL_COOKIE_VALUES = ['no-cookie-found|null', 'null-user-id'];

export class LoginEmailStepStore implements AuthStore {
  public titleKey: string;
  public subtitleKey?: string;
  public showLoginEmailStep: boolean;
  constructor(private rootStore: RootStore) {
    this.setDefaultShowLoginInEmailStep();
    const { displayStore } = rootStore;
    this.titleKey =
      displayStore.preset.login?.title?.key ?? 'emailStep.login.title';
    this.subtitleKey = displayStore.preset.login?.subtitle?.key;
    makeObservable(this, {
      showLoginEmailStep: observable,
      onShowLoginEmailStep: action.bound,
    });
  }

  clear(): void {
    this.showLoginEmailStep = false;
  }

  onInitFields(): void {
    throw new Error('Method not implemented.');
  }
  presetSubtitleKey?: string | undefined;

  private setDefaultShowLoginInEmailStep() {
    const {
      navigationStore: {
        loginDialogContext,
        defaultEmail,
        originUrl,
        postLoginUrl,
        postSignupUrl,
        getQueryParam,
      },
    } = this.rootStore;
    const wixUIDXCookie = getCookie('_wixUIDX');
    const hasCookie = !!(
      wixUIDXCookie && !NULL_COOKIE_VALUES.includes(wixUIDXCookie)
    );
    // TODO - Delete when finish investigating new login failure
    wixUIDXCookie &&
      this.rootStore.addAttributeToWixRecorder('wixUIDXCookie', wixUIDXCookie);

    const referralUrl = originUrl || document.referrer;
    const referralUrlSignupIndication =
      referralUrl &&
      referralUrl !== postLoginUrl &&
      referralUrl !== postSignupUrl;
    const isStudioEarlyAccess =
      getQueryParam('studio') === 'true' &&
      getQueryParam('referralInfo') === 'studio-early-access';
    if (loginDialogContext === SIGNUP_PAGE_CONTEXT || isStudioEarlyAccess) {
      this.showLoginEmailStep = false;
    } else {
      this.showLoginEmailStep =
        hasCookie ||
        !referralUrlSignupIndication ||
        loginDialogContext === LOGIN_PAGE_CONTEXT ||
        !!defaultEmail ||
        this.rootStore.displayStore.isEditorX;
    }
  }

  public onShowLoginEmailStep() {
    this.showLoginEmailStep = true;
  }
}
