import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import s from './ResetPasswordInvalid.scss';
import { DialogHeader } from '../DialogHeader/DialogHeader';
import { dataHooks } from '../../dataHooks';
import { Box } from '@wix/design-system';
import { AppContextProvider } from '../AppLoader';
import { DialogContainer } from '../DialogContainer';
import {
  ThemedButton,
  ThemedText,
  ThemedTextButton,
} from '../ThemedComponents';
import { Notification } from '../Common/Notification';

export const ResetPasswordInvalid: React.FC = observer(() => {
  const { t } = useTranslation();
  const {
    rootStore: {
      resetPasswordInvalidStore: {
        titleKey,
        descriptionKey,
        isTokenAlreadyUsed,
      },
    },
  } = useContext(AppContextProvider);
  return (
    <Box direction="vertical" width="100%" align="center">
    <DialogContainer
      dataHook={dataHooks.resetPasswordInvalid.container}
      mainHeaderProps={{ showBackButton: false }}
      className={s.resetPasswordInvalidContainer}
    >
      <DialogHeader>
        <DialogHeader.Title flow='secondaryFlow'>{t(titleKey)}</DialogHeader.Title>
        <DialogHeader.SubTitleSmall
          weight="normal"
          size="medium"
          className={s.descriptionText}
        >
          {t(descriptionKey)}
        </DialogHeader.SubTitleSmall>
      </DialogHeader>
      <Box direction="vertical" textAlign="center">
        {isTokenAlreadyUsed ? (
          <ResetPasswordAlreadyUsedAction />
        ) : (
          <ResetPasswordExpiredAction />
        )}
      </Box>
    </DialogContainer>
    </Box>
  );
});

const ResetPasswordExpiredAction: React.FC = observer(() => {
  const { t } = useTranslation();
  const {
    rootStore: {
      resetPasswordInvalidStore: { resendNotificationStore, onResendExpired },
    },
  } = useContext(AppContextProvider);
  return (
    <>
      <Box position="absolute">
        <Notification
          store={resendNotificationStore}
          dataHook={dataHooks.resetPasswordInvalid.notification}
        />
      </Box>
      <ThemedText>
        <Trans i18nKey="reset_password.expired.action">
          Didn’t get the email?
          <ThemedTextButton
            onClick={onResendExpired}
            dataHook={dataHooks.resetPasswordInvalid.actionLink}
            skin="standard"
          >
            Resend Email
          </ThemedTextButton>
        </Trans>
      </ThemedText>
    </>
  );
});

const ResetPasswordAlreadyUsedAction: React.FC = observer(() => {
  const { t } = useTranslation();
  const {
    rootStore: {
      resetPasswordInvalidStore: { alreadyUsedLink },
    },
  } = useContext(AppContextProvider);
  return (
    <ThemedButton as="a" href={alreadyUsedLink}>
      {t('reset_password.already_used.action')}
    </ThemedButton>
  );
});
