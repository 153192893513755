import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Box, Loader } from '@wix/design-system';
import { AppContextProvider } from '../AppLoader';
import ChangeAccountEmail from './ChangeAccountEmail';
import ManagePassword from './ManagePassword';
import ConfirmDetails from './ConfirmDetails';
import ConfirmEmail from './ConfirmEmail';
import Phone from './Phone';
import VerifyCode from './VerifyCode';
import PostLoginOwnerApp2Fa from './OwnerApp2Fa/PostLoginOwnerApp2Fa';
import { OwnerAppAddAuthWizard } from './PremiumUsers2FaEnforcement/OwnerApp/OwnerAppAuthWizard';
import { AddPhoneModal } from './PremiumUsers2FaEnforcement/Sms/AddPhoneModal';
import { AuthenticatorAppCodeModal } from './PremiumUsers2FaEnforcement/AuthenticatorApp/AuthenticatorAppCodeModal';
import { ConfirmationCodeStepModal } from './PremiumUsers2FaEnforcement/Email/ConfirmationCodeStepModal';
import { AddOwnerAppAsAdditionalModal } from './PremiumUsers2FaEnforcement/OwnerApp/AsAddOn/AddOwnerAppAsAdditionalModal';
import { AuthenticatorAppQrModal } from './PremiumUsers2FaEnforcement/AuthenticatorApp/AuthenticatorAppQrModal';
import { ConfirmExitEnforcement } from './PremiumUsers2FaEnforcement/ConfirmExitEnforcement';
import { ChooseVerificationMethodModal } from './PremiumUsers2FaEnforcement/ChooseVerificationMethodModal';
import { ConfirmEmailModal } from './PremiumUsers2FaEnforcement/Email/ConfirmEmailModal';
import { WixAppWizard } from './WixAppEnforcement/WixAppWizard';

const PostLoginRouter: React.FC = observer(() => {
  const { rootStore } = useContext(AppContextProvider);
  const { postLoginStore } = rootStore;

  useEffect(() => {
    postLoginStore.init();
    rootStore.triggerWixRecorder()
  }, []);

  return postLoginStore.isLoaded ? (
    <Switch>
      <Route path={postLoginStore.postLoginRoutes.CONFIRM_DETAILS} exact>
        <ConfirmDetails />
      </Route>
      <Route path={postLoginStore.postLoginRoutes.CONFIRM_EMAIL} exact>
        <ConfirmEmail />;
      </Route>
      <Route path={postLoginStore.postLoginRoutes.CONFIRM_DETAILS_PHONE} exact>
        <Phone.Confirm />;
      </Route>
      <Route
        path={postLoginStore.postLoginRoutes.CONFIRM_DETAILS_PHONE_VERIFY_CODE}
        exact
      >
        <VerifyCode.ConfirmDetailsPhone />;
      </Route>
      <Route path={postLoginStore.postLoginRoutes.CHANGE_ACCOUNT_EMAIL} exact>
        <ChangeAccountEmail />
      </Route>
      <Route path={postLoginStore.postLoginRoutes.CONFIRM_EMAIL_CODE}>
        <VerifyCode.ConfirmEmail />
      </Route>
      <Route
        path={postLoginStore.postLoginRoutes.CHANGE_ACCOUNT_EMAIL_VERIFY_CODE}
      >
        <VerifyCode.EmailChange />
      </Route>
      <Route path={postLoginStore.postLoginRoutes.RECOVERY_PHONE} exact>
        <Phone.Recovery />
      </Route>
      <Route path={postLoginStore.postLoginRoutes.RECOVERY_PHONE_VERIFY_CODE}>
        <VerifyCode.RecoveryPhone />
      </Route>
      <Route path={postLoginStore.postLoginRoutes.CHANGE_PASSWORD} exact>
        <ManagePassword.Change />
      </Route>
      <Route path={postLoginStore.postLoginRoutes.CREATE_PASSWORD} exact>
        <ManagePassword.Create />
      </Route>
      <Route path={postLoginStore.postLoginRoutes.PHONE_2FA} exact>
        <Phone.Mfa />
      </Route>
      <Route path={postLoginStore.postLoginRoutes.OWNER_APP_2FA} exact>
        <PostLoginOwnerApp2Fa />
      </Route>
      <Route
        path={postLoginStore.postLoginRoutes.PREMIUM_USERS_2FA_ENFORCEMENT}
        exact
      >
        <ChooseVerificationMethodModal />
      </Route>

      {postLoginStore.shouldShowWixAppEnforcement &&
        <Route path={postLoginStore.postLoginRoutes.WIX_APP_ENFORCEMENT} exact>
          <WixAppWizard />
        </Route>
      }
      <Route
        path={postLoginStore.postLoginRoutes.PREMIUM_USERS_2FA_WIX_OWNER_APP}
        exact
      >
        <OwnerAppAddAuthWizard />
      </Route>
      <Route
        path={
          postLoginStore.postLoginRoutes
            .PREMIUM_USERS_2FA_WIX_OWNER_APP_SECOND_METHOD
        }
        exact
      >
        <AddOwnerAppAsAdditionalModal />
      </Route>
      <Route
        path={
          postLoginStore.postLoginRoutes.PREMIUM_USERS_2FA_AUTHENTICATOR_APP
        }
        exact
      >
        <AuthenticatorAppQrModal />
      </Route>
      <Route path={postLoginStore.postLoginRoutes.PREMIUM_USERS_2FA_SMS} exact>
        <AddPhoneModal />
      </Route>
      <Route
        path={postLoginStore.postLoginRoutes.PREMIUM_USERS_2FA_EMAIL}
        exact
      >
        <ConfirmEmailModal />
      </Route>
      <Route
        path={postLoginStore.postLoginRoutes.PREMIUM_USERS_ADD_PHONE}
        exact
      >
        <AddPhoneModal />
      </Route>
      <Route
        path={postLoginStore.postLoginRoutes.PREMIUM_USERS_ENTER_AUTH_APP_CODE}
        exact
      >
        <AuthenticatorAppCodeModal />
      </Route>
      <Route
        path={postLoginStore.postLoginRoutes.PREMIUM_USERS_ENTER_EMAIL_CODE}
        exact
      >
        <ConfirmationCodeStepModal />
      </Route>
      <Route
        path={
          postLoginStore.postLoginRoutes.PREMIUM_USERS_CONFIRM_EXIT_ENFORCEMENT
        }
        exact
      >
        <ConfirmExitEnforcement />
      </Route>
      <Route path={postLoginStore.postLoginRoutes.PHONE_2FA_AGGRESSIVE} exact>
        <Phone.Mfa aggresive={true} />
      </Route>
      <Route path={postLoginStore.postLoginRoutes.PHONE_2FA_VERIFY_CODE} exact>
        <VerifyCode.Phone2FA />
      </Route>
      <Redirect to={postLoginStore.postLoginRoutes.CONFIRM_DETAILS} />
    </Switch>
  ) : (
    <Box height="50vh" verticalAlign="bottom" direction="vertical">
      <Loader />
    </Box>
  );
});

export default PostLoginRouter;
