import { SEARCH_PARAMS } from '../utils/constants';
import { AccountData } from '../types';
import { action, computed, makeObservable, observable } from 'mobx';
import { ERROR_CODES } from '../utils/errorHandler';
import { Location } from 'history';
import { RootStore } from './root';
import { ROUTES } from '../routes';
import { unescapeHtmlEntities } from '../utils/validators';
import { AUTH_TYPE } from '../components/BlockedAccount/authTypes';
import { FUNNELS_WITHOUT_SSO } from './constants';

const DEFAULT_ACCOUNT_NAME = 'unknown';

export class SsoStore {
  private readonly rootStore: RootStore;
  public accountName: string;
  public accountId: string;
  public accountImage: string;
  public existingWixAccountEmail: string;
  public ssoRedirectUrl: string;
  public inviteToken: string;
  public errorCode: string;
  public initialSsoUrl: string;
  public ssoPageUrl: string;
  private urlParams: URLSearchParams = new URLSearchParams(window.location.search);
  private isDebug = this.urlParams.get('debugCase') === 'sso' || null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.ssoRedirectUrl = window.__SSO_REDIRECT_URL__;
    this.existingWixAccountEmail = window.__SSO_EXISTING_WIX_ACCOUNT_EMAIL__ ?? this.ssoExistingWixAccountEmailQuery;
    const accountName = unescapeHtmlEntities(window.__SSO_ACCOUNT_NAME__) ?? this.accountNameQuery;
    this.accountName = accountName ? accountName : DEFAULT_ACCOUNT_NAME;
    this.accountImage = window.__SSO_ACCOUNT_IMAGE__ ?? this.accountImageQuery;
    this.accountId = window.__SSO_ACCOUNT_IMAGE__ ?? this.accountIdQuery;
    this.errorCode = window.__ERROR_CODE__;
    this.inviteToken = this.urlParams.get(SEARCH_PARAMS.INVITE_TOKEN) ?? '';
    makeObservable(this, {
      accountName: observable,
      ssoRedirectUrl: observable,
      errorCode: observable,
      ssoPageUrl: observable,
      setSsoPageUrl: action,
      routeChangedCallback: action,
      navigateAccountSelector: action.bound,
      redirectSsoUrl: action.bound,
      isSsoMode: computed,
      isSSOEnabledForFunnel: computed,
    });
    this.rootStore.navigationStore.registerHistoryChanges(
      this.routeChangedCallback,
    );
    this.routeChangedCallback(this.rootStore.navigationStore.history.location);
  }

  public routeChangedCallback = (location: Location) => {
    if (
      location.pathname === ROUTES.SSO_MAIN &&
      this.errorCode !== ERROR_CODES.SSO_ERROR_MISSING_OPENID_AUTH_METHOD &&
      this.errorCode === ERROR_CODES.SSO_ERROR_EMAIL_ALREADY_EXIST
    ) {
      this.initialSsoUrl = window.location.href;
      this.rootStore.navigationStore.navigate(ROUTES.SSO_EMAIL_ALREADY_EXIST);
    }
    if (
      location.pathname === ROUTES.SSO_MAIN &&
      this.errorCode === ERROR_CODES.USER_IS_BLOCKED
    ) {
      this.initialSsoUrl = window.location.href;
      this.rootStore.navigationStore.navigateToBlockedAccount( {refferal_info: AUTH_TYPE.SSO} );
    }
  };

  public filterSsoAccounts(accountsData: AccountData[]) {
    return accountsData.filter((account) => account.accountSsoSettings);
  }

  get isSsoMode(): boolean {
    return (
      this.rootStore.navigationStore.currentRoute ===
        ROUTES.SSO_EMAIL_ALREADY_EXIST ||
      this.rootStore.navigationStore.currentRoute === ROUTES.SSO_ENTER_EMAIL ||
      this.rootStore.navigationStore.currentRoute === ROUTES.SSO_MAIN
    );
  }

  public navigateAccountSelector({ email }: { email: string }) {
    this.rootStore.userDataStore.setEmail(email);
    this.rootStore.navigationStore.navigateToAccountSelector();
  }

  public getSsoTargetUrl() {
    const navigation = this.rootStore.navigationStore;
    return navigation.postLoginUrl
      ? navigation.postLoginUrl
      : navigation.targetUrl;
  }

  public redirectSsoUrl({ email, ssoUrl }: { email: string; ssoUrl: string }) {
    const navigation = this.rootStore.navigationStore;
    const url = new URL(ssoUrl);
    url.searchParams.set(SEARCH_PARAMS.TARGET_URL, this.getSsoTargetUrl());
    url.searchParams.set(SEARCH_PARAMS.EMAIL, email);
    navigation.redirect(url.toString());
  }

  public setSsoPageUrl(ssoPageUrl: string) {
    this.ssoPageUrl = ssoPageUrl;
  }

  get ssoExistingWixAccountEmailQuery(): string {
    return (this.isDebug && this.urlParams.get('ssoExistingWixAccountEmail')) ?? '';
  }

  get accountNameQuery(): string {
    return (this.isDebug && this.urlParams.get('accountName')) ?? '';
  }

  get accountImageQuery(): string {
    return (this.isDebug && this.urlParams.get('accountImage')) ?? '';
  }

  get accountIdQuery(): string {
    return (this.isDebug && this.urlParams.get('accountId')) ?? '';
  }

  get isSSOEnabledForFunnel(): boolean {
    const presetId = this.rootStore.displayStore?.preset?.id ?? '';
    return !FUNNELS_WITHOUT_SSO.includes(presetId);
  }
}
