import React, { useContext, useEffect, useRef } from 'react';
import { Box, ButtonProps, Loader } from '@wix/design-system';
import { ThemedButton } from '../ThemedComponents';
import Arrow from '../../assets/images/arrow.svg';
import s from './ButtonWithLoader.scss';
import classNames from 'classnames';
import { AppContextProvider } from '../AppLoader';

export const BEHAVIORS = {
  MULTI_CLICKS: 'multiClicks',
  ONE_TIME: 'oneTime',
} as const;
export type BehaviorType = (typeof BEHAVIORS)[keyof typeof BEHAVIORS];

type ButtonWithLoaderProps = ButtonProps & {
  onMouseDown?: (e: any) => void;
  showLoader?: boolean;
  showArrowOnOver?: boolean;
  autoFocus?: boolean;
  behaviour?: BehaviorType;
};

export const ButtonWithLoader = React.forwardRef<any, ButtonWithLoaderProps>(
  (
    { showLoader, showArrowOnOver, autoFocus, suffixIcon, behaviour = BEHAVIORS.MULTI_CLICKS, // Default to multiClicks
 ...props },
    ref: any,
  ) => {
    const {
      rootStore: {
        displayStore: { preset },
      },
    } = useContext(AppContextProvider);

  const [isLoading, setIsLoading] = React.useState(false);
  const behaviors = {
    [BEHAVIORS.MULTI_CLICKS]: (e) => {
      setIsLoading(true);
      Promise.all([props?.onClick?.(e)])
        .catch(() => {})
        .finally(() => setIsLoading(false));
    },
    [BEHAVIORS.ONE_TIME]: (e) => {
      setIsLoading(true);
      Promise.all([props?.onClick?.(e)]).catch(() => {
        setIsLoading(false);
      });
    }};

  const onClick = React.useCallback(
    (e: any) => {
      if (isLoading) {
        return;
      }
      behaviors[behaviour](e);
    },
    [isLoading, behaviour],
  );
    return (
      <Box width={preset.buttonDesign?.fullWidth ? '100%' : 'auto'}>
        <ThemedButton
          ref={ref}
          size="large"
          {...props}
          className={classNames(
            {
              [s.arrowAnimation]: showArrowOnOver && !isLoading && !showLoader,
            },
            props.className
          )}
          suffixIcon={
            <ButtonSuffixIcon
              showLoader={showLoader}
              suffixIcon={suffixIcon}
              isLoading={isLoading}
              showArrowOnOver={showArrowOnOver}
              disabled={props.disabled}
            />
          }
          onClick={onClick}
        >
          <span className={s.buttonContent}>
            {isLoading || showLoader ? <CustomButtonLoader /> : props.children}
          </span>
        </ThemedButton>
      </Box>
    );
  }
);

const CustomButtonLoader: React.FC = () => (
  <Box>
    <Loader size="tiny" />
  </Box>
);

const ButtonSuffixIcon: React.FC<
  ButtonWithLoaderProps & {
    isLoading: boolean;
  }
> = ({ showArrowOnOver, showLoader, disabled, suffixIcon, isLoading }) => {
  if (disabled || isLoading || showLoader) {
    return null;
  }

  if (showArrowOnOver) {
    return (
      <span className={classNames(s.arrowIcon, s.icon)}>
        <Arrow />
      </span>
    );
  }
  return <>{suffixIcon}</>;
};
