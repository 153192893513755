import React, { useState } from 'react';
import { observer } from 'mobx-react';
import App from './App';
import { RootStore } from '../stores/root';
import { Router } from 'react-router-dom';
import { WixDesignSystemProvider } from '@wix/design-system';
import { theme } from '@wix/design-system/dist/cjs/Themes/brand';
import { useExperiments } from '@wix/wix-experiments-react';
import { AccountSettingsHttpApi } from '../services/accountSettingsApi';
import { create, FedopsLogger } from '@wix/fedops-logger';
import { FEDOPS_APP_NAME } from '../utils/constants';
import webBiLogger from '@wix/web-bi-logger';
import { i18n as i18nType } from 'i18next';
import { Logger } from '@wix/web-bi-logger/dist/src/types';
import { DealerApi } from '../services/dealerApi';

import '@wix/design-system/styles.global.css';
import { PasswordStrengthExtractor } from '@wix/identity-fed-common';
import { BiProfileServiceApi } from '../services/biProfileApi';
import { PRESETS } from '../utils/presets';

export interface AppContext {
  rootStore: RootStore;
}

interface AppLoaderProps {
  createAccountSettingsApi: (
    biLogger: Logger,
    fedopsLogger: FedopsLogger,
  ) => AccountSettingsHttpApi;
  createDealerApi: () => DealerApi;
  i18n: i18nType;
}

export const AppContextProvider = React.createContext<AppContext>(undefined!);
export const AppLoader: React.FC<AppLoaderProps> = observer(
  ({ i18n, createAccountSettingsApi, createDealerApi }) => {
    const { experiments } = useExperiments();
    const [externals] = useState(() => {
      const fedopsLogger = create(FEDOPS_APP_NAME);
      const biLogger = webBiLogger.factory().logger();
      const accountSettingsApi = createAccountSettingsApi(
        biLogger,
        fedopsLogger,
      );
      const biProfileServiceApi = new BiProfileServiceApi();
      const dealerApi = createDealerApi();
      const passwordStrengthExtractor = new PasswordStrengthExtractor();

      const rootStore = new RootStore(
        accountSettingsApi,
        biProfileServiceApi,
        dealerApi,
        i18n,
        passwordStrengthExtractor,
        experiments,
      );
      globalThis.rootStore = rootStore;

      return {
        rootStore,
      };
    });
    return (
      <AppContextProvider.Provider value={{ rootStore: externals.rootStore }}>
        <WixDesignSystemProvider
          features={{
            newColorsBranding: true,
          }}
          {...(externals.rootStore.displayStore.presetKey !== PRESETS.WIXEL && {
            theme: theme(),
          })}
        >
          <Router history={externals.rootStore.navigationStore.history}>
            <App data-hook="appRootDataHook" />
          </Router>
        </WixDesignSystemProvider>
      </AppContextProvider.Provider>
    );
  },
);
