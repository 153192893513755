import React, {useContext} from 'react';
import {observer} from 'mobx-react';
import {
  Box,
  Button,
  Heading,
  Loader,
  Text,
  TextButton,
  Image, SectionHelper,
} from '@wix/design-system';
import {useTranslation} from 'react-i18next';
import {AppContextProvider} from '../../AppLoader';
import {SendNotificationState} from '../../../stores/premiumUsers2FaEnforcement/ownerApp/ownerApp';
import scanQrImage from '../../../assets/images/scanQR.svg?url';
import s from '../PremiumUsers2FaEnforcement/modals.scss';
import {HelpCircleTooltip} from "./HelpCircleTooltip";
import {InfoCircle} from "@wix/wix-ui-icons-common";

export const DownloadAppQrCodeStep: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: {ownerAppStore: store},
      },
    },
  } = useContext(AppContextProvider);
  const {t} = useTranslation();
  const stepNumberMarginLeft = 18;
  return (
    <Box width={600} height={448}>
      {store.showNoDevicesError && (
        <Box
          position="absolute"
          top="24px"
          left="50%"
          transform="translateX(-50%)"
        >
          <SectionHelper
            appearance="danger"
            size="small"
            showCloseButton
            showPrefixIcon
            prefixIcon={<InfoCircle size={18}/>}
            onClose={() => store.showNoDevicesError = false}
          >
            {t(
              'wixAppEnforcement.addOwnerApp.wizard.download.qrcode.no-devices-error',
            )}
          </SectionHelper>
        </Box>
      )}
      <Box width={576} marginLeft={4}>
        <Box dataHook="leftSide" direction="vertical" width={278} paddingTop={4} paddingBottom={4}
             justify-content={'space-between'}>
          <Box dataHook={"titleAndSteps"} direction="vertical" gap={4}>
            <Box dataHook={"titleAndStepsTitle"} direction="vertical" gap="6px" width={276}>
              <Heading dataHook="header title" size="medium">
                {t(
                  'wixAppEnforcement.addOwnerApp.wizard.download.qrcode.title',
                )}
              </Heading>
              <Text size="medium" weight="thin" secondary>
                {t('wixAppEnforcement.addOwnerApp.wizard.download.qrcode.steps.title')}
              </Text>
            </Box>
            <Box dataHook={"steps"} paddingRight={"40px"}>
              <Text size="medium" weight="thin" className={s.steps}>
                <ol style={{margin: 0}}>
                  <li style={{marginBottom: "12px", marginLeft: stepNumberMarginLeft}}>
                    {t(
                      'wixAppEnforcement.addOwnerApp.wizard.download.qrcode.steps.1',
                    )}
                  </li>
                  <li style={{marginBottom: "12px", marginLeft: stepNumberMarginLeft}}>
                    {t(
                      'wixAppEnforcement.addOwnerApp.wizard.download.qrcode.steps.2',
                    )}
                  </li>
                  <li style={{marginBottom: 0, marginLeft: stepNumberMarginLeft, marginRight: "0"}}>
                    {t(
                      'wixAppEnforcement.addOwnerApp.wizard.download.qrcode.steps.3',
                    )}
                  </li>
                </ol>
              </Text>
            </Box>
          </Box>
          <Box dataHook={"continueAndUseAnotherMethod"} width={180} height={72} gap={4} direction={'vertical'}>
            <Box style={{width: "max-content"}}>
              {/*TODO: replace with buttonwithloader*/}
              <Button
                size="small"
                onClick={store.nextStep}
              >
                {store.sendNotificationState ===
                SendNotificationState.Notifying ? (
                  <Loader size="tiny"/>
                ) : (
                  t(
                    'wixAppEnforcement.addOwnerApp.wizard.download.qrcode.button',
                  )
                )}
              </Button>
            </Box>
            <TextButton dataHook={"useAnotherMethod"}
                        size="small"
                        weight="thin"
                        underline="always"
                        skin="dark"
                        onClick={store.onUseAnotherVerificationMethod}
            >
              {t(
                'wixAppEnforcement.addOwnerApp.wizard.confirm.another.method',
              )}
            </TextButton>
          </Box>
        </Box>
        <Box
          dataHook={"rightSide"}
          width={300}
          backgroundColor="#E7F0FF"
          align="center"
          direction="vertical"
        >
          <Box dataHook={"helpCircle"} width="100%" align="right" marginTop={2} marginRight={2}>
            <HelpCircleTooltip/>
          </Box>
          <Box align="center" width="100%" paddingTop={"51px"}>
            <Image
              src={scanQrImage}
              transparent={true}
              width={184}
              height={225}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
