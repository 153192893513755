import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { AppContextProvider } from '../AppLoader';
import { SocialLoginButton, SocialLoginIcon } from '../SocialLoginButton';
import { FACEBOOK_CONTAINER_CLASS } from '../../stores/facebookLogin';
import s from './FacebookLoginButton.scss';
import classNames from 'classnames';
import { dataHooks } from '../../dataHooks';

export const FacebookLoginButton: React.FC<{ className?: string }> = observer(
  ({ className }) => {
    const {
      rootStore: {
        facebookLoginStore,
        displayStore: { socialButtonsWidth },
      },
    } = useContext(AppContextProvider);
    return (
      <SocialLoginButton
        socialStore={facebookLoginStore}
        dataHook={dataHooks.socials.facebookBtn}
        loaderWidth={socialButtonsWidth}
        buttonClassName={className}
      >
        {facebookLoginStore.useNewSdk ? (
          <>
            <div
              className={classNames(FACEBOOK_CONTAINER_CLASS, {
                [s.facebookButtonHidden]: !facebookLoginStore.isRendered,
              })}
              data-size="large"
              data-button-type="continue_with"
              data-layout="rounded"
              data-auto-logout-link="false"
              data-use-continue-as="true"
              data-onlogin="onFacebookLogin"
              data-width={socialButtonsWidth}
            ></div>
            {!facebookLoginStore.isRendered && (
              <SocialLoginButton.CustomLoginButton />
            )}
          </>
        ) : (
          <SocialLoginButton.CustomLoginButton />
        )}
      </SocialLoginButton>
    );
  },
);

export const FacebookLoginIcon: React.FC<{ className?: string }> = observer(
  ({ className }) => {
    const {
      rootStore: { facebookLoginStore },
    } = useContext(AppContextProvider);
    return (
      <SocialLoginIcon
        socialStore={facebookLoginStore}
        additionalProps={{ type: 'icon', shape: 'circle' }}
        dataHook={dataHooks.socials.facebookIcon}
        buttonClassName={className}
      >
        <SocialLoginButton.CustomLoginIcon />
      </SocialLoginIcon>
    );
  },
);
