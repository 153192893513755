import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  when,
} from 'mobx';
import { DEFAULT_ROUTE, ROUTES } from '../routes';
import { SEARCH_PARAMS } from '../utils/constants';
import { RootStore } from './root';
import { SocialProviderLoginStore } from './socialProviderLogin';

interface GoogleService {
  accounts: {
    id: {
      renderButton: Function;
      initialize: Function;
    };
  };
}

const GOOGLE_CLIENT_ID = '352188552355.apps.googleusercontent.com';
const GOOGLE_LOGIN_INTERACTION = 'google-login';

export class GoogleLoginStore extends SocialProviderLoginStore<LoginToWixGoogleParams> {
  private googleService: GoogleService;
  private auth2: any;
  readonly googleBtnClassName = 'googleBtnContainer';
  providerId: string;
  externalState: string;
  externalIdToken: string;
  hasInitError: boolean = false;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.useNewSdk = this.rootStore.experiments?.enabled(
      'specs.ident.UseNewGoogleSdkReactLoginApp',
    );
    this.providerId = 'google';
    this.hasError = this.rootStore.experiments?.enabled(
      'specs.ident.ShowGoogleErrorMsg',
    );
    this.customButtonLogoUrl =
      'https://wixmp-7ef3383b5fd80a9f5a5cc686.wixmp.com/logos/google-logo.svg';
    this.init();
    this.reactToSocialError();
    when(
      () => this.isInitialized,
      () => this.handleExternalGoogleLogin(),
    );
    makeObservable(this, {
      externalState: observable,
      externalIdToken: observable,
      isGoogleExternalLogin: computed,
      googleReadyPredicate: computed,
      init: action,
      onLogin: action,
    });
  }

  protected reactToSocialError() {
    reaction(
      () =>
        this.rootStore.facebookLoginStore?.hasError ||
        this.rootStore.appleLoginStore?.hasError,
      (newError) => {
        if (newError) {
          this.hasError = false;
        }
      },
    );
  }

  onClickCustomLoginButton(): void {
    if (this.hasInitError) {
      this.hasError = true;
      return;
    }
    this.rootStore.fedopsLogger.interactionStarted(GOOGLE_LOGIN_INTERACTION);
    this.socialButtonClicked();
    this.auth2
      ?.signIn()
      .then(async (googleUser) => {
        await this.onLogin(googleUser.getAuthResponse());
        this.rootStore.fedopsLogger.interactionEnded(GOOGLE_LOGIN_INTERACTION);
      })
      .catch((error) => this.handleError({ error, showErrorStatus: false }));
  }

  private initGoogleDomainParams() {
    const hashParams = new URLSearchParams(window.location.hash.slice(1));
    this.externalState =
      hashParams.get(SEARCH_PARAMS.GOOGLE_STATE) ??
      this.rootStore.navigationStore.getQueryParam(SEARCH_PARAMS.GOOGLE_STATE);
    this.externalIdToken =
      hashParams.get(SEARCH_PARAMS.GOOGLE_ID_TOKEN) ??
      this.rootStore.navigationStore.getQueryParam(
        SEARCH_PARAMS.GOOGLE_ID_TOKEN,
      );
  }

  get googleReadyPredicate() {
    return this.useNewSdk ? window.google : window.gapi;
  }

  get isGoogleExternalLogin() {
    return this.externalState && this.externalIdToken;
  }

  private handleExternalGoogleLogin() {
    if (!this.isGoogleExternalLogin) {
      if (this.isGoogleLoginRoute()) {
        this.rootStore.navigationStore.navigate(DEFAULT_ROUTE);
      }
      return;
    }
    if (!this.isGoogleLoginRoute()) {
      this.rootStore.navigationStore.navigate(ROUTES.GOOGLE_LOGIN);
    }
    this.onLogin({
      state: this.externalState,
      id_token: this.externalIdToken,
    });
  }

  private isGoogleLoginRoute() {
    return this.rootStore.navigationStore.currentRoute === ROUTES.GOOGLE_LOGIN;
  }

  private initSdk() {
    if (this.useNewSdk) {
      this.googleService = window.google;
      this.googleService?.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        auto_select: true, // determines if an ID token is automatically returned without any user interaction when there's only one Google session that has approved wix before
        callback: (authResult: GoogleAuthResult) => this.onLogin(authResult),
      });
      this.isInitialized = true;
    } else {
      window.gapi?.load('auth2', () => {
        this.auth2 = window.gapi.auth2.init({
          client_id: GOOGLE_CLIENT_ID,
          scope: 'openid profile email',
        });
        this.auth2.then(
          (auth2Res) => {
            if (!auth2Res || !auth2Res.signIn) {
              return (this.hasError = true);
            }
            this.isInitialized = true;
          },
          () => {
            this.hasInitError = true;
          },
        );
      });
    }
  }

  async init() {
    this.initGoogleDomainParams();
    window.googleSdkLoaded.then(() => this.initSdk());
    if (this.isGoogleExternalLogin && !this.isGoogleLoginRoute()) {
      this.rootStore.navigationStore.navigate(ROUTES.GOOGLE_LOGIN);
    }
  }

  initializeSdk(options: GsiButtonConfiguration = {}) {
    if (!this.useNewSdk) {
      return;
    }

    const width = this.rootStore.displayStore.socialButtonsWidth === '100%' ? '300px' : '320px';

    this.googleService.accounts.id.renderButton(
      document.getElementsByClassName(this.googleBtnClassName)[0],
      {
        theme: 'filled_blue',
        shape: 'rectangular',
        size: 'large',
        width,
        text: 'continue_with',
        locale: this.rootStore.locale,
        logo_alignment: 'left',
        click_listener: () => this.socialButtonClicked(),
        ...options,
      },
    );
  }

  get enableAccess(): boolean {
    return !!this.rootStore.activeSocialProvider;
  }

  async onLogin(authResult: GoogleAuthResult) {
    const { credential, state, id_token, error } = authResult;
    const isWixHomeGoogleOneTapFlow =
      state === 'googleOT' || state === 'googleButton';

    if (error || (!id_token && !credential)) {
      this.handleError({ error, state });
      return;
    }

    const res = await this.loginToWix(
      { id_token: (id_token ? id_token : credential)! },
      isWixHomeGoogleOneTapFlow ? { authMethod: 'GOOGLE_ONE_TAP' } : {},
    );
    if (!res.success) {
      this.handleLoginError(res.errorCode);
    }
  }

  handleLoginError(errorCode?: string) {
    const { navigationStore } = this.rootStore;
    if (navigationStore.currentRoute === ROUTES.GOOGLE_LOGIN) {
      navigationStore.navigateToDefaultRoute();
    }
  }
}
