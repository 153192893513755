import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { Image, ImageProps, TextButton } from '@wix/design-system';
import s from './Logo.scss';
import { dataHooks } from '../../dataHooks';
import { IS_BLANK_GRAVATAR } from '../../utils/constants';
import { AppContextProvider } from '../AppLoader';
import classNames from 'classnames';
import { LogoPosition } from '../../utils/presets';

interface LogoProps extends ImageProps {
  logoUrl?: string;
  dataHook?: string;
  logoClassName?: string;
  height?: string;
  targetUrl?: string;
  secondary?: boolean;
  position?: LogoPosition;
}

export const Logo: React.FC<LogoProps> = observer(
  ({
    logoUrl = '',
    dataHook = dataHooks.logo.default,
    logoClassName,
    height = '35px',
    targetUrl,
    secondary = false,
    position,
    ...props
  }) => {
    const {
      rootStore: {
        navigationStore,
        displayStore: { isMobile, isVerticalLayout },
      },
    } = useContext(AppContextProvider);
    const style = secondary ? { height: '26px' } : { height };
    return (
      <TextButton
        className={classNames(s.logo, {
          [s.a11yFocus]: targetUrl,
          [s.mobile]: isMobile,
          [s.vertical]: isVerticalLayout,
          [s.secondaryLogo]: secondary,
          [s.centered]: position === 'center',
        })}
        dataHook={dataHook}
        style={{
          cursor: targetUrl ? 'pointer' : 'default',
        }}
        onClick={() => targetUrl && navigationStore.redirect(targetUrl)}
      >
        <Image
          alt="Wix"
          borderRadius="0px"
          src={logoUrl}
          style={{
            ...style,
            width: 'auto',
          }}
          className={logoClassName}
          transparent={!IS_BLANK_GRAVATAR.test(logoUrl)}
          {...props}
        />
      </TextButton>
    );
  }
);
