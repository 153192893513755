import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { SubmitButton } from '../SubmitButton';
import { Trans, useTranslation } from 'react-i18next';
import { dataHooks } from '../../dataHooks';
import { AppContextProvider } from '../AppLoader';
import { DialogHeader } from '../DialogHeader/DialogHeader';
import s from './EmailAlreadyExist.scss';
import { DialogFormContainer } from '../DialogContainer';
import { ThemedText, ThemedTextButton } from '../ThemedComponents';
import { Box } from '@wix/design-system';

const EmailAlreadyExist: React.FC = observer(() => {
  const { t } = useTranslation();
  const {
    rootStore: { ssoEmailAlreadyExistStore },
  } = useContext(AppContextProvider);
  const {
    titleKey,
    contentKey,
    transParams,
    submitButtonKey,
    linkKey,
    secondaryLogoUrl,
    ssoArticleLink,
  } = ssoEmailAlreadyExistStore;
  return (
    <Box direction="vertical" align="center" width="100%">
      <DialogFormContainer
        mainHeaderProps={{ secondaryLogoUrl, showBackButton: false }}
        formClasses={[s.ssoEmailAlreadyExist]}
        className={s.emailAlreaydExistsContainer}
        dataHook={dataHooks.sso.emailAlreadyExistDialog.container}
      >
        <>
          <DialogHeader className={s.title}>
            <DialogHeader.Title flow='secondaryFlow'>{t(titleKey, transParams)}</DialogHeader.Title>
            <DialogHeader.SubTitleSmall>
              <Trans i18nKey={contentKey} values={transParams}>
                <ThemedText>To log in with SSO using </ThemedText>
                <ThemedText weight="bold">email</ThemedText>
                <ThemedText>
                  change the email of your existing account.
                </ThemedText>
              </Trans>
            </DialogHeader.SubTitleSmall>
          </DialogHeader>
          <SubmitButton
            className={s.commandButton}
            onClick={() => ssoEmailAlreadyExistStore.goToAccountSettings()}
            skin='standard'
          >
            {t(submitButtonKey)}
          </SubmitButton>
          <ThemedTextButton
            target="_blank"
            as="a"
            href={ssoArticleLink}
            className={s.linkText}
            underline="always"
          >
            {t(linkKey)}
          </ThemedTextButton>
        </>
      </DialogFormContainer>
    </Box>
  );
});

export default EmailAlreadyExist;
