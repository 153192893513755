import {makeObservable, observable, reaction} from 'mobx';
import {RESEND_INDICATION_TTL_TIMER, RESEND_INDICATION_TTL_TIMER_FOR_WIX_APP_ENFORCEMENT} from '../constants';
import { PremiumUsers2FaEnforcementStore } from './premiumUsers2FaEnforcement';

export class VerifyCode {
  public resendSuccessfullyIndication: boolean = false;
  public resendThrottleTimerValue: number = this.resendIndicationTtl;
  private resendTimerInterval: NodeJS.Timer;

  constructor(
    protected premiumUsers2FaEnforcementStore: PremiumUsers2FaEnforcementStore,
  ) {
    makeObservable(this, {
      resendSuccessfullyIndication: observable,
      resendThrottleTimerValue: observable,
    });
    this.reactToResend();
  }

  get resendIndicationTtl() {
    return this.premiumUsers2FaEnforcementStore.postLoginStore.shouldShowWixAppEnforcement ?
      RESEND_INDICATION_TTL_TIMER_FOR_WIX_APP_ENFORCEMENT / 1000 :
      RESEND_INDICATION_TTL_TIMER / 1000
  }

  proceedToPostAuthUrl(reason: string) {
    this.premiumUsers2FaEnforcementStore.postLoginStore.proceedToPostAuthUrl(
      reason,
    );
    return Promise.resolve();
  }

  private setResendThrottleTimer = () => {
    this.resendThrottleTimerValue = this.resendIndicationTtl;
    this.resendTimerInterval = setInterval(() => {
      if (this.resendThrottleTimerValue > 1) {
        this.resendThrottleTimerValue--;
      } else {
        clearInterval(this.resendTimerInterval);
        this.resendSuccessfullyIndication = false;
      }
    }, 1000);
  };

  private reactToResend() {
    reaction(
      () => this.resendSuccessfullyIndication,
      (resendSuccessfullyIndication) => {
        if (!resendSuccessfullyIndication) {
          clearInterval(this.resendTimerInterval);
          return;
        }
        return this.setResendThrottleTimer();
      },
    );
  }
}
