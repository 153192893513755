import React, { ForwardedRef, useContext } from 'react';
import { observer } from 'mobx-react';
import { AppContextProvider } from '../AppLoader';

// Try to create a nice displayName for React Dev Tools.
const getDisplayName = <T,>(WrappedComponent: React.ComponentType<T>) =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component';

export function withTextTheme<T extends { light?: boolean }>(
  WrappedComponent: React.ComponentType<T>,
) {
  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentWithTheme: React.FC<T & { light?: boolean } & any> = observer(
    (props: T & any) => {
      const {
        rootStore: { displayStore },
      } = useContext(AppContextProvider);

      // props comes afterwards so the can override the default ones.
      return (
        <WrappedComponent
          light={displayStore.preset.skin === 'light'}
          {...props}
        />
      );
    },
  );

  ComponentWithTheme.displayName = `withTextTheme(${getDisplayName<T>(
    WrappedComponent,
  )})`;

  return ComponentWithTheme;
}

export function withSkinTheme<T extends { skin?: string; fullWidth?: boolean }>(
  WrappedComponent: React.ComponentType<T>,
) {
  const ComponentWithTheme = React.forwardRef<React.ElementRef<any>, T>(
    (props, ref) => {
      const {
        rootStore: {
          displayStore: { preset },
        },
      } = useContext(AppContextProvider);
      const skin = preset.skin === 'standard' ? 'dark' : preset.skin;

      return (
        <WrappedComponent
          ref={ref}
          skin={skin}
          fullWidth={preset.buttonDesign?.fullWidth}
          {...props}
        />
      );
    },
  );

  const ObservedComponentWithTheme = observer(ComponentWithTheme);

  ObservedComponentWithTheme.displayName = `withSkinTheme(${getDisplayName<T>(
    WrappedComponent,
  )})`;

  return ObservedComponentWithTheme;
}
