import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { AppContextProvider } from '../AppLoader';
import { SocialLoginButton, SocialLoginIcon } from '../SocialLoginButton';
import s from './AppleLoginButton.scss';
import classNames from 'classnames';
import { dataHooks } from '../../dataHooks';

export const AppleLoginButton: React.FC<{ className?: string }> = observer(
  ({ className }) => {
    const {
      rootStore: {
        appleLoginStore,
        displayStore: { preset, authFormWidth, socialButtonsWidth },
      },
    } = useContext(AppContextProvider);
    return (
      <SocialLoginButton
        socialStore={appleLoginStore}
        buttonClassName={classNames({
          [s.appleBtnContainer]: appleLoginStore.useNewSdk,
          [s.error]: appleLoginStore.hasError,
          className,
        })}
        dataHook={dataHooks.socials.appleBtn}
        loaderWidth={socialButtonsWidth}
      >
        {appleLoginStore.useNewSdk ? (
          <div
            className={`${s.appleBtn} ${
              preset?.skin === 'light' ? s.white : ''
            }`}
            id="appleid-signin"
            data-color={preset?.skin === 'light' ? 'white' : 'black'}
            data-border="true"
            data-type="continue"
            data-logo-size="large"
            data-mode="left-align"
            data-label-position="80"
            data-logo-position="15"
            data-height="38"
            data-border-radius="50"
            data-width={authFormWidth}
            onClick={() => appleLoginStore.socialButtonClicked()}
          ></div>
        ) : (
          <SocialLoginButton.CustomLoginButton />
        )}
      </SocialLoginButton>
    );
  },
);

export const AppleLoginIcon: React.FC<{ className?: string }> = observer(
  ({ className }) => {
    const {
      rootStore: {
        appleLoginStore,
        displayStore: { preset },
      },
    } = useContext(AppContextProvider);
    return (
      <SocialLoginIcon
        socialStore={appleLoginStore}
        dataHook={dataHooks.socials.appleIcon}
        buttonClassName={className}
      >
        {appleLoginStore.useNewSdk ? (
          <div
            className={classNames(s.appleIcon, className)}
            id="appleid-signin"
            data-color={preset?.skin === 'light' ? 'white' : 'black'}
            data-border="true"
            data-mode="logo-only"
            data-height="30"
            data-border-radius="50"
          ></div>
        ) : (
          <SocialLoginButton.CustomLoginIcon />
        )}
      </SocialLoginIcon>
    );
  },
);
