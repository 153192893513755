import React, { useContext } from 'react';
import {TextButton, Tooltip} from '@wix/design-system';
import { observer } from 'mobx-react';
import { AppContextProvider } from '../../AppLoader';
import {HelpCircle} from "@wix/wix-ui-icons-common";
import {useTranslation} from "react-i18next";

export const HelpCircleTooltip: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: { ownerAppStore: store },
      },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  return (
    <Tooltip
      maxWidth={266}
      placement="top"
      content={
        <>
          {t(
            'wixAppEnforcement.addOwnerApp.wizard.select.help.tooltip',
          )}
          <span>&nbsp;</span>
          <TextButton
            size="small"
            weight="thin"
            skin="light"
            underline="always"
            onClick={() => {
              store.onClickLearnMoreLink();
              window.open(store.learnMoreLink, '_blank');
            }}
          >
            {t(
              'wixAppEnforcement.addOwnerApp.wizard.select.learn.more',
            )}
          </TextButton>
        </>
      }
    >
      <HelpCircle />
    </Tooltip>
  );
});
