import { computed, makeObservable, observable } from 'mobx';
import { RootStore } from './root';

export class PoliciesStore {
  private readonly rootStore: RootStore;
  public readonly termsOfUseUrl: string;
  public readonly captchaTermsOfUseUrl: string;
  public readonly privacyPolicyUrl: string;
  public readonly captchaPrivacyPolicyUrl: string;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    const getPublicAbsoluteUrl =
      this.rootStore.navigationStore.getPublicAbsoluteUrl;
    const policiesPrefix = this.rootStore.displayStore.isEditorX
      ? 'info'
      : 'about';
    this.termsOfUseUrl = getPublicAbsoluteUrl(
      `/${policiesPrefix}/terms-of-use`
    );
    this.privacyPolicyUrl = getPublicAbsoluteUrl(`/${policiesPrefix}/privacy`);
    this.captchaTermsOfUseUrl = 'https://policies.google.com/terms';
    this.captchaPrivacyPolicyUrl = 'https://policies.google.com/privacy';

    makeObservable(this, {
      termsOfUseUrl: observable,
      privacyPolicyUrl: observable,
      captchaTermsOfUseUrl: observable,
      captchaPrivacyPolicyUrl: observable,
      verticalPrivacyPolicyUrl: computed,
      verticalTermsOfUseUrl: computed,
      expandedPolicyKey: computed,
    });
  }
  get expandedPolicyKey(): string {
    if (this.rootStore.displayStore.preset.policies?.policyKey) {
      return this.rootStore.displayStore.preset.policies.policyKey;
    }
    return this.rootStore.displayStore.isWixEducation
      ? 'policies_section.signup.education'
      : 'policies_section.signup_content';
  }

  get verticalTermsOfUseUrl(): string {
    return this.rootStore.displayStore.isWixEducation
      ? this.rootStore.navigationStore.getPublicAbsoluteUrl(
          '/education/terms-of-use'
        )
      : '';
  }

  get verticalPrivacyPolicyUrl(): string {
    return this.rootStore.displayStore.isWixEducation
      ? this.rootStore.navigationStore.getPublicAbsoluteUrl(
          '/education/wix-education-privacy-policy'
        )
      : '';
  }
}
