import React, {useContext} from 'react';
import {observer} from 'mobx-react';
import {
  Box,
  Button,
  Heading,
  Loader,
  Text,
  TextButton,
  Image,
} from '@wix/design-system';
import {useTranslation} from 'react-i18next';
import {AppContextProvider} from '../../AppLoader';
import wixAppDevice from '../../../assets/images/wixAppDevice.svg?url';
import {SendNotificationState} from '../../../stores/premiumUsers2FaEnforcement/ownerApp/ownerApp';
import {HelpCircleTooltip} from "./HelpCircleTooltip";

export const DownloadAppIntroStep: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: {ownerAppStore: store},
      },
    },
  } = useContext(AppContextProvider);
  const {t} = useTranslation();
  return (
    <>
      <Box width={600}>
        <Box marginLeft={4}>
          <Box
            dataHook="leftSide"
            direction="vertical"
            width={270}
            padding="24px 0"
            align={"space-between"}
          >
            <Box dataHook={"header"} direction="vertical" width={239} gap={1}>
              <Box>
                <Heading dataHook="header title" size="medium">
                  {t(
                    'wixAppEnforcement.addOwnerApp.wizard.download.intro.title',
                  )}
                </Heading>
              </Box>
              <Text size="medium" weight="thin" secondary>
                {t(
                  'wixAppEnforcement.addOwnerApp.wizard.download.intro.subtitle',
                )}
              </Text>
            </Box>
            <Box direction="vertical" gap={4}>
              <Box>
                  <Button
                    size="small"
                    onClick={store.nextStep}
                  >
                    {store.sendNotificationState ===
                    SendNotificationState.Notifying ? (
                      <Loader size="tiny"/>
                    ) : (
                      t(
                          'wixAppEnforcement.addOwnerApp.wizard.download.intro.button',
                        )
                    )}
                  </Button>
              </Box>
              <TextButton
                size="small"
                weight="thin"
                underline="always"
                skin="dark"
                onClick={store.onUseAnotherVerificationMethod}
              >
                {t(
                  'wixAppEnforcement.addOwnerApp.wizard.confirm.another.method',
                )}
              </TextButton>
            </Box>
          </Box>
        </Box>
        <Box
          width={314}
          height={405}
          backgroundColor="#E7F0FF"
          align="center"
          direction="vertical"
        >
          <Box width="100%" align="right" marginTop={2} marginRight={2}>
            <HelpCircleTooltip/>
          </Box>
          <Box marginTop="auto">
            <Image src={wixAppDevice} transparent={true}/>
          </Box>
        </Box>
      </Box>
    </>
  );
});
