export const confirmationCodeActions = {
  SEND: 'send',
  RESEND: 'resend',
} as const;

export type Values<T> = T[keyof T];

export enum EnforcementFlow {
  DEFAULT,
  USER_HAS_NO_APP
}
