import React, { FC, useContext } from 'react';
import { CustomModalLayout } from '@wix/design-system';
import { observer } from 'mobx-react';
import { OwnerAppStore } from '../../../stores/premiumUsers2FaEnforcement/ownerApp/ownerApp';
import { AppContextProvider } from '../../AppLoader';
import { EnforcementModal } from '../PremiumUsers2FaEnforcement/EnforcementModal';
import { UiStore } from './WixAppWizardUiStore';

export const WixAppWizard: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: { ownerAppStore: store },
      },
    },
  } = useContext(AppContextProvider);
  if (!store || store.activeStep === undefined) {
    return <EnforcementModal />;
  }
  const uiStore = new UiStore(store);
  return (
    <EnforcementModal>
      <CustomModalLayout
        removeContentPadding
        title={uiStore.title}
        subtitle={uiStore.subtitle}
        primaryButtonText={uiStore.primaryButtonText}
        primaryButtonOnClick={uiStore.primaryButtonOnClick}
        width={uiStore.modalWidth}
        height={uiStore.modalHeight}
        maxHeight={uiStore.modalMaxHeight}
        className={uiStore.className}
        sideActions={uiStore.sideActions}
        showFooterDivider={uiStore.showFooterDivider}
        showHeaderDivider={uiStore.showHeaderDivider}
      >
        <CurrentStep store={store} uiStore={uiStore} />
      </CustomModalLayout>
    </EnforcementModal>
  );
});

const CurrentStep: FC<{ store: OwnerAppStore; uiStore: UiStore }> = observer(
  ({ store, uiStore }) => {
    const { authStatus } = store;
    const stepComponent = uiStore.component;

    return typeof stepComponent === 'function'
      ? stepComponent(authStatus)
      : stepComponent || null;
  },
);
