import { useContext, useState, useEffect } from 'react';
import { CustomFontSize, DialogFlow } from './DialogHeader';
import { AppContextProvider } from '../AppLoader';

export const useHeadingSize = (
  customFontSize?: CustomFontSize,
  flow?: DialogFlow
) => {
  const {
    rootStore: {
      displayStore: { preset, isMobile, isVerticalLayout },
    },
  } = useContext(AppContextProvider);
  const [headingSize, setHeadingSize] = useState<{
    as: string;
    customFontSize?: CustomFontSize;
  }>();

  useEffect(() => {
    const as = 'h1';
    if (customFontSize) {
      return setHeadingSize({
        as,
        customFontSize,
      });
    }
    if (flow === 'authPage') {
      return setHeadingSize({
        as,
        customFontSize: isMobile
          ? preset.titleCustomFontSize?.authPage.mobile
          : preset.titleCustomFontSize?.authPage.desktop,
      });
    }
    return setHeadingSize({
      as: 'h3',
      customFontSize: isMobile
        ? preset.titleCustomFontSize?.secondaryFlow.mobile
        : preset.titleCustomFontSize?.secondaryFlow.desktop,
    });
  }, [customFontSize, flow, isMobile, isVerticalLayout, preset]);

  return {
    as: headingSize?.as,
    fontSize: headingSize?.customFontSize,
  };
};
