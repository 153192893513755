import localeData from '@wix/locale-dataset-javascript';
import {
  action,
  computed,
  makeObservable,
  observable,
  override,
  runInAction,
} from 'mobx';
import React from 'react';
import { Box } from '@wix/design-system';
import { POST_LOGIN_OFFERS } from '../utils/constants';
import { constraints } from '../utils/validators';
import { FormField } from './formField';
import { PostLoginStore } from './postLogin';
import { RootStore } from './root';
import { VerifyCodeStore } from './verifyCode';
import {
  postLoginChangeDetailsClickedContinue,
  postLoginResendCode,
  postLoginVerifyDetails,
  postLoginRecoveryPhoneDetails,
  postLoginMfaDetails,
} from '@wix/bi-logger-post-login/v2';

export interface CountryCodeOption {
  value: string | React.ReactNode;
  id: string;
  countryCode: string;
  alpha2Code: string;
  icon?: React.ReactNode;
}

export enum UserChoice {
  RecoveryNumber = 0,
  BillingNumber = 1,
  NewNumber = 2,
}

export class PhoneStore extends VerifyCodeStore {
  public phoneNumber: FormField;
  public title: string;
  public subtitle: string;
  public selectNumberText: string;
  public countryCode: CountryCodeOption;
  public userChoice: UserChoice = UserChoice.RecoveryNumber;
  public countriesFilter: string = '';
  public countryCodesOptions: CountryCodeOption[] = [];
  public isSubmitted: boolean = false;
  public newInternationalNumber: string;
  public onCodeVerified?: () => void;
  public onCloseCallback?: () => void;
  private postLoginOfferId: string;
  private verificationId: string;
  private readonly postLoginStore: PostLoginStore;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.postLoginStore = rootStore.postLoginStore;
    makeObservable(this, {
      title: observable,
      subtitle: observable,
      selectNumberText: observable,
      phoneNumber: observable,
      countryCode: observable,
      userChoice: observable,
      countriesFilter: observable,
      countryCodesOptions: observable,
      isSubmitted: observable,
      onCodeVerified: observable,
      onCloseCallback: observable,
      resendThrottleTimerValue: override,
      onResendConfirmationCode: override,
      onVerifyConfirmationCode: override,
      onSendVerificationSms: action,
      onSelectCountryCode: action.bound,
      clean: action,
      onCancel: action,
      getSelectedInternationalPhone: action,
      onSearchCountries: action,
      userChoiceChange: action,
      phone: computed,
      isPrimaryExist: computed,
      isEditing: computed,
    });
    this.updateCountriesList();
    this.phoneNumber = new FormField(this.phone?.nationalNumber, [
      [
        constraints.required,
        this.rootStore.i18n.t('validation.phone.badNumber'),
      ],
      [
        constraints.minimum(5),
        this.rootStore.i18n.t('validation.phone.badNumber'),
      ],
      [constraints.number, this.rootStore.i18n.t('validation.phone.badNumber')],
    ]);

    this.postLoginOfferId =
      this.postLoginStore.postLoginOffer.asset.creative.identifier;

    const errorMsg = this.rootStore.i18n.t('post.login.enter_code.errorInfo');
    this.confirmCode = new FormField(undefined, [
      [constraints.required, errorMsg],
      [constraints.minimum(6), errorMsg],
      [constraints.maximum(6), errorMsg],
      [
        constraints.number,
        this.rootStore.i18n.t('post.login.enter_code.incorrect'),
      ],
    ]);
    const confirmedCountryCode = this.phone?.countryCode;
    this.countryCode = this.countryCodesOptions.find((option) => {
      // Finding the country code base on the suggested user alpha2Code code as we receive it from the server
      if (!confirmedCountryCode) {
        return (
          option.alpha2Code ===
          rootStore.postLoginStore.userPhone?.requestAlpha2Code
        );
      }
      // If the user have phone saved already we use the saved data to find the right option.
      // In case the country code is +1 we fall back to US.
      // The reason we have this fallback is that our server doesn't support country prop
      // but computed phone number (country code + phone number).
      if (confirmedCountryCode === '+1') {
        return option.alpha2Code === 'US';
      }
      return option.countryCode === confirmedCountryCode;
    })!;
  }

  public userChoiceChange = (choice: any) => {
    this.userChoice = choice;
  };

  public showLater = () => {
    if (this.postLoginOfferId === POST_LOGIN_OFFERS.RECOVERY_PHONE) {
      this.rootStore.biLogger.report(
        postLoginRecoveryPhoneDetails({
          button: 'Not Now',
        })
      );
    } else if (
      this.postLoginOfferId === POST_LOGIN_OFFERS.MFA ||
      this.postLoginOfferId === POST_LOGIN_OFFERS.MFA_AGGRESSIVE
    ) {
      this.rootStore.biLogger.report(
        postLoginMfaDetails({
          button: 'Not Now',
          mfa_type: 'Phone',
          type: UserChoice[this.userChoice],
        })
      );
    }

    this.rootStore.postLoginStore.showLater();
  };

  private updateCountriesList() {
    const allCountries = localeData.getAllCountries().map((country) => {
      const countryName = this.rootStore.i18n.t(`country.name.${country.key}`);
      const flag = (
        <Box width="31px" height="28px" padding="2px 4px 4px 4px">
          <img
            src={`//static.parastorage.com/unpkg/@wix/wix-locale-data@0.0.3632/dist/images/svg-country-flags/${country.key}.svg`}
            alt={countryName}
          />
        </Box>
      );
      return {
        value: (
          <Box>
            {flag}
            {`${country.shortKey} ${country.dialCode}`}
          </Box>
        ),
        id: `${country.shortKey} ${country.dialCode}`,
        countryCode: country.dialCode,
        alpha2Code: country.shortKey,
        icon: <Box marginTop="3px">{flag}</Box>,
      };
    });
    if (this.countriesFilter?.length) {
      const lowerCaseFilter = this.countriesFilter.toLocaleLowerCase();
      this.countryCodesOptions = allCountries.filter((country) =>
        country.id?.toLowerCase().includes(lowerCaseFilter)
      ) as CountryCodeOption[];
    } else {
      this.countryCodesOptions = allCountries as CountryCodeOption[];
    }
  }

  public async onSendVerificationSms() {
    if (!this.phoneNumber.isValid && !this.isUsingPrimaryNumberFlow) {
      return;
    }

    const sendBiEvent = (error?: any) => {
      if (
        this.postLoginOfferId === POST_LOGIN_OFFERS.MFA ||
        this.postLoginOfferId === POST_LOGIN_OFFERS.MFA_AGGRESSIVE
      ) {
        this.rootStore.biLogger.report(
          postLoginMfaDetails({
            button: 'Next',
            mfa_type: 'Phone',
            type: UserChoice[this.userChoice],
            error_info: error,
          })
        );
      } else if (this.postLoginOfferId === POST_LOGIN_OFFERS.CONFIRM_DETAILS) {
        this.rootStore.biLogger.report(
          postLoginChangeDetailsClickedContinue({
            methodType: 'Phone',
            error_info: error,
          })
        );
      } else if (this.postLoginOfferId === POST_LOGIN_OFFERS.RECOVERY_PHONE) {
        this.rootStore.biLogger.report(
          postLoginRecoveryPhoneDetails({
            button: 'Next',
            phone_type: UserChoice[this.userChoice],
            error_info: error,
          })
        );
      }
    };

    try {
      this.isSubmitted = true;
      const response: any =
        await this.rootStore.accountSettingsApi.sendVerificationCode({
          phone: this.isUsingPrimaryNumberFlow
            ? this.getPrimary()
            : {
                countryCode: this.countryCode.countryCode,
                nationalNumber: this.phoneNumber.value,
              },
        });

      if (response?.data?.result?.success) {
        sendBiEvent();
        this.verificationId = response?.data?.verificationId;
        return this.navigateToCodeConfirmation();
      }

      throw response;
    } catch (error: any) {
      this.isSubmitted = false;

      const errorCode =
        error?.response?.data?.errorCode ||
        error?.response?.data?.error?.metadata?.code ||
        error?.data?.errorCode ||
        error?.failureReason;
      const errorMessage =
        this.rootStore.accountSettingsApi.errorHandling(errorCode);
      if (!this.isUsingPrimaryNumberFlow) {
        runInAction(() => {
          this.phoneNumber.addError(this.rootStore.i18n.t(errorMessage));
        });
      }

      sendBiEvent(errorMessage);
      return;
    }
  }

  public async onResendConfirmationCode() {
    this.resendErrorMessage = null;
    try {
      const response: any =
        await this.rootStore.accountSettingsApi.sendVerificationCode({
          phone: this.isUsingPrimaryNumberFlow
            ? this.getPrimary()
            : {
                countryCode: this.countryCode.countryCode,
                nationalNumber: this.phoneNumber.value,
              },
        });

      if (!response?.data?.result?.success) {
        throw response;
      }
      this.verificationId = response?.data?.verificationId;
      if (
        this.postLoginOfferId === POST_LOGIN_OFFERS.MFA ||
        this.postLoginOfferId === POST_LOGIN_OFFERS.MFA_AGGRESSIVE
      ) {
        this.rootStore.biLogger.report(
          postLoginResendCode({
            flow_type: 'MFA',
            methodType: 'SMS',
          })
        );
      } else if (this.postLoginOfferId === POST_LOGIN_OFFERS.CONFIRM_DETAILS) {
        this.rootStore.biLogger.report(
          postLoginResendCode({
            flow_type: 'Confirm account details',
            methodType: 'SMS',
          })
        );
      } else if (this.postLoginOfferId === POST_LOGIN_OFFERS.RECOVERY_PHONE) {
        this.rootStore.biLogger.report(
          postLoginResendCode({
            flow_type: 'Recovery phone',
            methodType: 'SMS',
          })
        );
      }
    } catch (error: any) {
      const errorCode =
        error?.response?.data?.errorCode || error?.data?.errorCode;
      throw errorCode;
    }
  }

  public async onVerifyConfirmationCode(): Promise<void> {
    const {
      countryCode: primaryCountryCode,
      nationalNumber: primaryNationalNumber,
      internationalNumber: primaryInternationalNumber,
    } = this.getPrimary() || {};
    const nationalNumber = this.isUsingPrimaryNumberFlow
      ? primaryNationalNumber!
      : this.phoneNumber.value;
    const countryCode = this.isUsingPrimaryNumberFlow
      ? primaryCountryCode!
      : this.countryCode.countryCode;
    const internationalNumber = this.isUsingPrimaryNumberFlow
      ? primaryInternationalNumber!
      : `${countryCode}${nationalNumber}`;
    const sendBiEvent = (flowType: string, error?: any) => {
      this.rootStore.biLogger.report(
        postLoginVerifyDetails({
          error_info: error,
          flow_type: flowType,
          methodType: 'SMS',
        })
      );
    };
    try {
      if (
        this.postLoginOfferId !== POST_LOGIN_OFFERS.MFA &&
        this.postLoginOfferId !== POST_LOGIN_OFFERS.MFA_AGGRESSIVE
      ) {
        await this.rootStore.accountSettingsApi.verifyAndEnableRecoveryPhone(
          {
            countryCode,
            nationalNumber,
            internationalNumber,
          },
          this.confirmCode.value,
          this.verificationId
        );

        if (this.postLoginOfferId === POST_LOGIN_OFFERS.CONFIRM_DETAILS) {
          sendBiEvent('Confirm account details');
          this.rootStore.confirmDetailsStore.isRecoveryPhoneVerified = true;
          this.newInternationalNumber = internationalNumber;
          this.rootStore.navigationStore.navigate(
            this.rootStore.postLoginStore.postLoginRoutes.CONFIRM_DETAILS
          );
          return;
        }
        sendBiEvent('Recovery phone');
      } else {
        await this.rootStore.accountSettingsApi.verifyAndEnablePhoneTwoFA(
          {
            countryCode,
            nationalNumber,
            internationalNumber,
          },
          this.confirmCode.value,
          this.verificationId
        );
      }

      if (this.rootStore.notificationStore.isOpen) {
        this.rootStore.notificationStore.close();
      }

      this.onCodeVerified && this.onCodeVerified();
      this.rootStore.postLoginStore.offerSucceed();
    } catch (error: any) {
      if (this.postLoginOfferId === POST_LOGIN_OFFERS.CONFIRM_DETAILS) {
        sendBiEvent('Confirm account details', error);
      } else if (this.postLoginOfferId === POST_LOGIN_OFFERS.RECOVERY_PHONE) {
        sendBiEvent('Recovery phone', error);
      }
      const errorCode =
        error?.response?.data?.errorCode ||
        error?.data?.errorCode ||
        error?.data?.reason;

      throw errorCode;
    }
  }

  public onSelectCountryCode(option: CountryCodeOption) {
    this.countryCode = option;
  }

  public clean = () => {
    this.onCodeVerified = undefined;
    this.userChoice = UserChoice.RecoveryNumber;
    this.confirmCode.clear();
    this.phoneNumber.clear();
    this.isSubmitted = false;
    if (this.isEditing) {
      this.phoneNumber.value = this.phone!.nationalNumber!;
    }
    this.resendErrorMessage = null;
  };

  public onCancel() {
    this.onCloseCallback && this.onCloseCallback();
  }

  public getSelectedInternationalPhone(): string | undefined {
    return this.isUsingPrimaryNumberFlow || !this.countryCode
      ? this.getPrimary()?.internationalNumber
      : `${this.countryCode.countryCode}${this.phoneNumber.value}`;
  }

  public onSearchCountries(searchValue: string) {
    this.countriesFilter = searchValue;
    this.countryCode = this.countryCodesOptions.find(
      (c) => c.id === this.countriesFilter
    )!;
    this.updateCountriesList();
  }

  public navigateToCodeConfirmation() {
    let route: string;
    switch (this.postLoginOfferId) {
      case POST_LOGIN_OFFERS.CONFIRM_DETAILS:
        route =
          this.postLoginStore.postLoginRoutes.CONFIRM_DETAILS_PHONE_VERIFY_CODE;
        break;
      case POST_LOGIN_OFFERS.RECOVERY_PHONE:
        route = this.postLoginStore.postLoginRoutes.RECOVERY_PHONE_VERIFY_CODE;
        break;
      case POST_LOGIN_OFFERS.MFA:
      case POST_LOGIN_OFFERS.MFA_AGGRESSIVE:
        route = this.postLoginStore.postLoginRoutes.PHONE_2FA_VERIFY_CODE;
        break;
      default:
        route = this.postLoginStore.postLoginRoutes.RECOVERY_PHONE_VERIFY_CODE;
    }

    this.rootStore.navigationStore.navigate(route);
  }

  public stepToAddPhone() {
    this.resendErrorMessage = null;
  }

  get phone(): PhoneDTO | undefined {
    return this.rootStore.postLoginStore.userPhone?.Account?.Primary;
  }

  public getPrimary(): PhoneDTO | undefined {
    return this.rootStore.postLoginStore.userPhone?.Account?.Primary;
  }

  get isPrimaryExist(): boolean {
    return (
      this.rootStore.postLoginStore.userPhone?.Account?.Primary
        ?.verificationStatus === 'VERIFIED'
    );
  }

  get isUsingPrimaryNumberFlow(): boolean {
    return this.isPrimaryExist && this.userChoice === UserChoice.RecoveryNumber;
  }

  get isEditing(): boolean {
    return this.phone?.verificationStatus === 'VERIFIED';
  }

  get isPhoneRecoveryOffer(): boolean {
    return this.postLoginOfferId === POST_LOGIN_OFFERS.RECOVERY_PHONE;
  }

  get isConfirmDetailsOffer(): boolean {
    return this.postLoginOfferId === POST_LOGIN_OFFERS.CONFIRM_DETAILS;
  }
}
