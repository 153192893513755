import { makeObservable, observable } from 'mobx';
import { RootStore } from './root';
import { PasswordFormField } from './passwordFormField';
import { Constraint, constraints, ERROR_TRANSLATION_KEYS } from '../utils/validators';

export abstract class EnterPasswordStore {
  protected readonly rootStore: RootStore;
  public passwordField: PasswordFormField;
  abstract submit(): void;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    const fieldRequiredConstraint: Constraint[] = [
      [constraints.required, rootStore.i18n.t(ERROR_TRANSLATION_KEYS.REQUIRED)],
    ];
    this.passwordField = new PasswordFormField('', fieldRequiredConstraint);
    makeObservable(this, {
      passwordField: observable,
    });
  }

  get hasError(): boolean {
    return !!(this.passwordField.errorInfo || this.passwordField.serverError);
  }
  get isSubmittable(): boolean {
    return this.passwordField.isValid;
  }
}
