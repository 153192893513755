import React, { FC, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DialogHeader } from '../DialogHeader/DialogHeader';
import { Box, Image, InfoIcon, Text } from '@wix/design-system';
import ownerApp2FAPending from '../../assets/images/ownerApp2FAPending.svg?url';
import { OwnerApp2FAResendButton } from '../OwnerApp2FAResendButton/OwnerApp2FAResendButton';
import { ThemedText } from '../ThemedComponents';
import { useTwoFactorAuth } from '../../stores/twoFactorAuth';
import { MoreMethods } from '../OwnerApp2FA/OwnerApp2FAMoreMethods';
import { useExperiments } from '@wix/wix-experiments-react';
import { BodyContainer } from '../AuthPage/AuthPage';

export const OwnerApp2FAPending: FC = () => {
  const { store } = useTwoFactorAuth();
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const shouldShowExplanation = experiments.enabled(
    'spces.ident.OwnerApp2FAExplanation'
  );

  useEffect(() => {
    store.startPolling();

    return () => {
      store.stopPolling();
    };
  }, [store]);

  const deviceName =
    store.deviceName || t('ownerAppTwoFactorAuth.pending.unknownDevice');

  return (
    <BodyContainer>
      <DialogHeader>
        <DialogHeader.Title flow='secondaryFlow'>
          {t('ownerAppTwoFactorAuth.pending.title')}
        </DialogHeader.Title>
        <DialogHeader.SubTitleSmall size="medium" weight="thin">
          <Trans
            i18nKey="ownerAppTwoFactorAuth.pending.description"
            values={{ deviceName }}
          >
            <ThemedText>A login request was sent to:</ThemedText>
            <ThemedText weight="bold">deviceName</ThemedText>
            <ThemedText>Approve to log in.</ThemedText>
          </Trans>
        </DialogHeader.SubTitleSmall>
        <Box
          direction="vertical"
          margin="58px 0 21px 0"
          align="center"
          gap={1}
        >
          <Image src={ownerApp2FAPending} transparent width="120px" />
          {shouldShowExplanation && (
            <Box gap={1} verticalAlign="middle">
              <Text size={"medium"} weight={'thin'} secondary>
                {t('ownerAppTwoFactorAuth.pending.explanation')}
              </Text>
              <InfoIcon
                tooltipProps={{
                  maxWidth: '258px',
                  placement: 'top',
                }}
                size='small'
                content={t('ownerAppTwoFactorAuth.pending.explanation.tooltip')}
              />
            </Box>
          )}
          <OwnerApp2FAResendButton startWithTimer={true} />
        </Box>
      </DialogHeader>
      <Box direction="vertical" align="center" gap={0} width="100%">
        <MoreMethods />
      </Box>
    </BodyContainer>
  );
};
