import { Box, SectionHelper } from '@wix/design-system';
import { i18n } from 'i18next';
import { observer } from 'mobx-react';
import React, { createContext, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { dataHooks } from '../../dataHooks';
import { EnterEmailStore } from '../../stores/enterEmail';
import { AppContextProvider } from '../AppLoader';
import { BodyContainer } from '../AuthPage/AuthPage';
import { DialogFormContainer } from '../DialogContainer';
import { DialogHeader } from '../DialogHeader/DialogHeader';
import { InputFieldWithLabel } from '../InputField';
import { SubmitButton } from '../SubmitButton';
import { ThemedText } from '../ThemedComponents';
import s from './EnterEmail.scss';

interface EnterEmailProps {
  title?: string;
  subTitle?: string;
  buttonContent?: string;
  dataHook?: string;
  backButtonCb?: Function;
}

export const EnterEmailContextProvider = createContext<{
  store?: EnterEmailStore;
}>({});

export const EnterEmail: React.FC<EnterEmailProps> = observer(
  ({
    title,
    subTitle,
    buttonContent,
    backButtonCb,
    dataHook = dataHooks.sso.enterEmailDialog.container,
  }) => {
    const { store } = useContext(EnterEmailContextProvider);
    const {
      rootStore: {
        postSocialLoginEnterEmailStore,
        displayStore: { preset, isVerticalLayout },
      },
    } = useContext(AppContextProvider);
    const { t } = useTranslation();
    return (
      <Box direction="vertical" width="100%" align="center">
        <DialogFormContainer
          formClasses={[s.enterEmailContainer]}
          dataHook={dataHook}
          mainHeaderProps={{
            backButtonCallback: backButtonCb ?? store?.onBackButtonClicked,
          }}
        >
          <BodyContainer>
            <DialogHeader>
              <DialogHeader.Title flow='secondaryFlow'>{title}</DialogHeader.Title>
              <DialogHeader.SubTitleSmall>{subTitle}</DialogHeader.SubTitleSmall>
            </DialogHeader>
            <Box direction="vertical" align="center" >
              <InputFieldWithLabel
                className={s.emailInput}
                required
                formField={store?.emailField}
                label={t('sso.enterEmailPage.input.title')}
                dataHook={dataHooks.sso.enterEmailDialog.emailInput}
                onEnterPressed={() => store?.submit()}
                type="email"
                autocomplete="email"
                marginBottom="6px"
              />
              <SubmitButton
                dataHook={dataHooks.sso.enterEmailDialog.submitButton}
                onClick={() => store?.submit()}
                skin={preset.login?.primaryButtonDesign?.skin}
              >
                {buttonContent ?? t('sso.enterEmailPage.submitButton.text')}
              </SubmitButton>
            </Box>
          </BodyContainer>
        </DialogFormContainer>
        {postSocialLoginEnterEmailStore?.showErrorMessageForFacebook2FaUsers &&
          postSocialLoginEnterEmailStore?.facebookWith2FaNotificationOpen && (
            <Box width={540} marginTop="large">
              <SectionHelper
                appearance="danger"
                fullWidth={false}
                actionText={t(
                  'sso.enterEmailPage.facebookWithNo2FA.actionButton.text',
                )}
                showCloseButton={false}
                size="small"
                onAction={() =>
                  store?.goToLoginWithEmail(store.emailField.value)
                }
              >
                {t('sso.enterEmailPage.facebookWithNo2FA.content')}
              </SectionHelper>
            </Box>
          )}
      </Box>
    );
  }
);

const ErrorTemplate: React.FC<{
  i18nInstance: i18n;
  errorKey: string;
  errorCode?: string;
  linkCb?: () => void;
}> = ({ i18nInstance, errorKey = 'errors.general', errorCode, linkCb }) => {
  switch (errorKey) {
    case 'errors.email.sso.notAssociated':
      return (
        <Trans i18nKey={errorKey}>
          <ThemedText size="tiny" skin="error">
            You can't log in with SSO using this email address.
          </ThemedText>
        </Trans>
      );
    default:
      return (
        <ThemedText size="tiny" skin="error">
          {i18nInstance.t(errorKey, { errorCode })}
        </ThemedText>
      );
  }
};

export const getErrorTemplate = (
  i18nInstance: i18n,
  errorKey: string,
  errorCode?: string,
  linkCb?: () => void
) => (
  <ErrorTemplate
    i18nInstance={i18nInstance}
    errorKey={errorKey}
    errorCode={errorCode}
    linkCb={linkCb}
  />
);
