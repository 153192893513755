import { Box } from '@wix/design-system';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { dataHooks } from '../../dataHooks';
import { AppContextProvider } from '../AppLoader';
import { DialogHeader } from '../DialogHeader/DialogHeader';
import { EnterPassword, EnterPasswordContextProvider } from '../EnterPassword';
import s from './AccountVerification.scss';
import classNames from 'classnames';

const AccountVerification: React.FC = observer(() => {
  const {
    rootStore: {
      accountVerificationStore,
      displayStore: { isMobile, preset, isVerticalLayout },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();

  return (
    <EnterPasswordContextProvider.Provider
      value={{ store: accountVerificationStore }}
    >
      <Box direction="vertical" align="center" width="100%">
        <EnterPassword dataHook={dataHooks.accountVerification.container}>
          <DialogHeader>
            <DialogHeader.Title flow="secondaryFlow">
              {t('account_verification.title', {
                email: accountVerificationStore.email,
              })}
            </DialogHeader.Title>
            <DialogHeader.SubTitleSmall
              className={classNames(
                isMobile ? s.mobileDescription : s.desktopDescription,
                {
                  [s.vertical]: isVerticalLayout,
                }
              )}
            >
              <Trans
                i18nKey="account_verification.content"
                values={{
                  email: accountVerificationStore.email,
                  brand: t(preset?.nameKey ?? ''),
                }}
              >
                Looks like you already have an account with this email: email To
                log in & connect your social account, please enter your Wix
                password.
              </Trans>
            </DialogHeader.SubTitleSmall>
          </DialogHeader>
        </EnterPassword>
      </Box>
    </EnterPasswordContextProvider.Provider>
  );
});

export default AccountVerification;
