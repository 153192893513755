import {action, autorun, computed, makeObservable, observable, reaction} from 'mobx';
import { PostLoginStore } from '../postLogin';
import { VerificationMethodDefinitions } from '../../components/PostLogin/PremiumUsers2FaEnforcement/types';
import { NavigationStore } from '../navigation';
import { OwnerAppStore } from './ownerApp/ownerApp';
import { MultiFactorAuthStore, MultiFactorIdentifier } from './multiFactorAuth';
import { AddPhoneStore } from './sms/addPhone';
import WixExperiments from '@wix/wix-experiments';
import { AuthenticatorAppModalsStore } from './authApp/authenticatorAppModals';
import { ConfirmEmailStore } from './email/confirmEmail';
import { LoginInfoStore } from './loginInfo';
import {
  BI_BUTTON_NAMES,
  BI_ORIGIN,
  BI_SCREEN_NAMES,
  EXPERIMENTS,
} from '../constants';
import {
  app2FaSetupClickOnBack,
  faEnforcementAllFlowsSrc5Evid237,
} from '@wix/bi-logger-identity-data/v2';
import { ReportProps } from '@wix/web-bi-logger/dist/src/types';
import { Steps as OwnerAppSteps } from './ownerApp/types';
import {EnforcementFlow} from './types';

export class PremiumUsers2FaEnforcementStore {
  public selectedVerificationMethod: MultiFactorIdentifier;
  public navigationStore: NavigationStore =
    this.postLoginStore.rootStore.navigationStore;
  public ownerAppStore: OwnerAppStore;
  public multiFactorAuth: MultiFactorAuthStore = new MultiFactorAuthStore(this);
  public twoFASettings: TwoFASettingsDTO = {restrictions: []};
  public myAccountExperiments: WixExperiments = new WixExperiments({
    scope: 'my-account',
  });
  public loginInfoStore: LoginInfoStore;
  public add2FAPhoneStore: AddPhoneStore;
  public authenticatorAppModals: AuthenticatorAppModalsStore;
  public confirmEmail: ConfirmEmailStore;
  public shouldActivateNewColorBranding: boolean = false;
  public userHasOwnerAppInstalled: boolean;
  public enforcementFlow: EnforcementFlow = EnforcementFlow.DEFAULT;

  verificationMethods: VerificationMethodDefinitions = {
    [MultiFactorIdentifier.OwnerApp]: {
      nameKey: 'premium.users.2fa.enforcement.choose.choices.wixApp',
      nameBadgeKey:
        'premium.users.2fa.enforcement.choose.choices.wixApp.badge',
      route:
      this.postLoginStore.postLoginRoutes.PREMIUM_USERS_2FA_WIX_OWNER_APP,
      multiFactorIdentifier: MultiFactorIdentifier.OwnerApp,
    },
    [MultiFactorIdentifier.AuthApp]: {
      nameKey: 'premium.users.2fa.enforcement.choose.choices.authApp',
      route:
      this.postLoginStore.postLoginRoutes.PREMIUM_USERS_2FA_AUTHENTICATOR_APP,
      multiFactorIdentifier: MultiFactorIdentifier.AuthApp,
      tooltipTextKey: 'premium.users.2fa.enforcement.authenticator.app.tooltip',
    },
    [MultiFactorIdentifier.Phone]: {
      nameKey: 'premium.users.2fa.enforcement.choose.choices.sms',
      route: this.postLoginStore.postLoginRoutes.PREMIUM_USERS_2FA_SMS,
      multiFactorIdentifier: MultiFactorIdentifier.Phone,
    },
    [MultiFactorIdentifier.Email]: {
      nameKey: 'premium.users.2fa.enforcement.choose.choices.email',
      route: this.postLoginStore.postLoginRoutes.PREMIUM_USERS_2FA_EMAIL,
      multiFactorIdentifier: MultiFactorIdentifier.Email,
    },
  };

  constructor(public postLoginStore: PostLoginStore) {
    makeObservable(this, {
      selectedVerificationMethod: observable,
      twoFASettings: observable,
      fetchTwoFASettings: action,
      userHasOwnerAppInstalled: observable,
      goBackToEnforcement: action.bound,
      goBackToChooseBackupMethod: action.bound,
      goBackToEnforcementOrChooseBackupMethod: action.bound,
      init: action.bound,
      initStores: action.bound,
      setSelectedVerificationMethod: action.bound,
      ownerAppStore: observable,
      filteredVerificationMethods: computed,
      primaryButtonAction: computed,
      enforcementFlow: observable
    });
    reaction(
      () => this.ownerAppStore?.devices,
      () => {
        this.setSelectedVerificationMethod()
        this.userHasOwnerAppInstalled = this.ownerAppStore.hasConnectedDevices;
      },
    )
  }

  public setSelectedVerificationMethod() {
    this.selectedVerificationMethod =
      this.ownerAppStore.hasConnectedDevices || this.postLoginStore.shouldShowWixAppEnforcement
        ? MultiFactorIdentifier.OwnerApp
        : MultiFactorIdentifier.AuthApp;
  }

  public async init() {
    await this.fetchResources();
    await this.initStores();
    this.setSelectedVerificationMethod();
    this.shouldActivateNewColorBranding = this.myAccountExperiments.enabled(
      EXPERIMENTS.SHOULD_ACTIVATE_NEW_COLORS_BRANDING,
    );
  }

  public async fetchResources() {
    await Promise.all([this.fetchTwoFASettings()]);
  }

  public async fetchTwoFASettings() {
    this.twoFASettings =
      await this.postLoginStore.accountSettingsApi.getTwoFASettings();
  }

  public async initStores() {
    this.ownerAppStore = new OwnerAppStore(this);
    await this.ownerAppStore.fetchUserDevices();
    this.loginInfoStore = new LoginInfoStore(this);
    this.add2FAPhoneStore = new AddPhoneStore(this);
    this.authenticatorAppModals = new AuthenticatorAppModalsStore(this);
    this.confirmEmail = new ConfirmEmailStore(this);
    autorun(() => {
      this.setSelectedVerificationMethod()
      this.userHasOwnerAppInstalled = this.ownerAppStore.hasConnectedDevices;
    });
  }

  public t = (key: string) => this.postLoginStore.i18n.t(key);

  get isPostWixOwnerAppEnforcement() {
    return this.postLoginStore.shouldShowWixAppEnforcement && this.multiFactorAuth.OwnerApp.isEnabled
  }

  get subtitle() {
    return this.isPostWixOwnerAppEnforcement
      ? this.t(`${this.messagePrefix}premium.users.2fa.enforcement.postOwnerApp.subtitle`)
      : this.t(`${this.messagePrefix}premium.users.2fa.enforcement.subtitle`);
  }

  get primaryButtonText() {
    return this.t('premium.users.2fa.enforcement.primary');
  }

  get primaryButtonAction() {
    return async () => {
      this.sendBiEvent(
        faEnforcementAllFlowsSrc5Evid237({
          screenName: BI_SCREEN_NAMES.CHOOSE_VERIFICATION_METHOD,
          button_name: BI_BUTTON_NAMES.SET_UP,
          value: this.selectedVerificationMethod,
          isHover: false,
          hasOwnerApp: this.userHasOwnerAppInstalled,
        }),
      );
      await this.multiFactorAuth.onRequestAddFactor(
        this.selectedVerificationMethod as MultiFactorIdentifier,
      );
    };
  }

  get secondaryButtonText() {
    return this.isPostWixOwnerAppEnforcement
      ? this.t('premium.users.2fa.enforcement.secondary')
      : undefined;
  }

  get secondaryButtonAction() {
    return this.isPostWixOwnerAppEnforcement
      ? async () => {
        this.postLoginStore.proceedToPostAuthUrl('Add 2FA over owner app successful');
      }
      : undefined;
  }

  get modalHeight() {
    return 414;
  }

  onSelectVerificationMethod(id: MultiFactorIdentifier) {
    this.selectedVerificationMethod = id;
  }

  public goBackToEnforcement() {
    this.sendBiEvent(
      app2FaSetupClickOnBack({
        mode: 'enable',
        origin: BI_ORIGIN.TWO_FA_ENFORCEMENT,
      }),
    );
    this.postLoginStore.rootStore.navigationStore.navigate(
      this.postLoginStore.postLoginRoutes.PREMIUM_USERS_2FA_ENFORCEMENT,
    );
  }

  goBackToChooseBackupMethod() {
    this.ownerAppStore.activeStep = OwnerAppSteps.BackupMethodStep;
    if (this.postLoginStore.shouldShowWixAppEnforcement) {
      this.postLoginStore.rootStore.navigationStore.navigate(
        this.postLoginStore.postLoginRoutes.WIX_APP_ENFORCEMENT,
      );
    } else {
      this.postLoginStore.rootStore.navigationStore.navigate(
        this.postLoginStore.postLoginRoutes.PREMIUM_USERS_2FA_WIX_OWNER_APP,
      );
    }
  }

  public goBackToEnforcementOrChooseBackupMethod() {
    if (this.ownerAppStore.waitingForBackupMethod) {
      this.goBackToChooseBackupMethod();
    } else {
      this.goBackToEnforcement();
    }
  }

  public goToOwnerAppOrDashboard(reason: string) {
    if (
      !this.postLoginStore.shouldShowWixAppEnforcement &&
      !this.multiFactorAuth.OwnerApp.isEnabled &&
      this.userHasOwnerAppInstalled
    ) {
      this.goToAddOwnerApp();
    } else {
      this.postLoginStore.proceedToPostAuthUrl(reason);
    }
  }

  goToAddOwnerApp() {
    this.ownerAppStore.openModal(true).catch(() => {
    });
  }

  sendBiEvent(props: ReportProps) {
    this.postLoginStore.rootStore.biLogger.report(props).catch();
  }

  get filteredVerificationMethods() {
    return Object.entries(this.verificationMethods).filter(([key]) => {
      return !(this.excludeOwnerApp(key) ||
        this.excludeEmail(key) ||
        this.excludePhone(key) ||
        this.excludeAuthApp(key));
    });
  }

  private excludeAuthApp(key: string) {
    return key === MultiFactorIdentifier.AuthApp && this.multiFactorAuth.AuthApp.isEnabled;
  }

  private excludePhone(key: string) {
    return key === MultiFactorIdentifier.Phone && this.multiFactorAuth.Phone.isEnabled;
  }

  private excludeEmail(key: string) {
    return key === MultiFactorIdentifier.Email &&
      (this.multiFactorAuth?.Email?.isDomainRestricted || this.multiFactorAuth.Email.isEnabled);
  }

  private excludeOwnerApp(key: string) {
    return key === MultiFactorIdentifier.OwnerApp &&
      ((!this.userHasOwnerAppInstalled && !this.postLoginStore.shouldShowWixAppEnforcement) || this.multiFactorAuth.OwnerApp.isEnabled);
  }

  get messagePrefix() {
    return this.isWixAppEnforcement ? 'wixAppEnforcement.' : ''
  }

  get isWixAppEnforcement() {
    return this.postLoginStore.shouldShowWixAppEnforcement;
  }
}
