import { Text } from '@wix/design-system';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemedTextButton } from '../ThemedComponents';
import { useTwoFactorAuth } from '../../stores/twoFactorAuth';
import { loginOwnerApp2FaClickOnResendNotification } from '@wix/bi-logger-identity-data/v2';

export const OwnerApp2FAResendButton: FC<{startWithTimer: boolean}> = ({startWithTimer}) => {
  const { store, biLogger } = useTwoFactorAuth();
  const resendTimerInitialValue = store.getResendTimerInitialValue();
  const { t } = useTranslation();
  const [isResendClicked, setIsResendClicked] = useState(false);
  const [timer, setTimer] = useState(resendTimerInitialValue);

  const handleResendClick = async () => {
    await store.resendOwnerAppNotification();
    setIsResendClicked(true);
    biLogger.report(loginOwnerApp2FaClickOnResendNotification({}));
  };

  useEffect(() => {
    if (startWithTimer) {
      setIsResendClicked(true);
    }
  }, [startWithTimer]);

  useEffect(() => {
    if (isResendClicked) {
      const interval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            setIsResendClicked(false);
            clearInterval(interval);
            return resendTimerInitialValue;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isResendClicked]);

  if (isResendClicked) {
    return (
      <Text weight="thin" size="medium" skin="disabled" secondary>
        {t('ownerAppTwoFactorAuth.resendTimer', { timer })}
      </Text>
    );
  }

  return (
    <ThemedTextButton
      underline="always"
      size="medium"
      onClick={handleResendClick}
    >
      {t(store.getResendButtonKey())}
    </ThemedTextButton>
  );
};
