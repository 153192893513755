import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Box, RadioGroup, Text, Tooltip } from '@wix/design-system';
import { AppContextProvider } from '../../AppLoader';
import { dataHooks } from '../../../dataHooks';
import { BackupMethods } from '../../../stores/premiumUsers2FaEnforcement/ownerApp/ownerApp';
import { Chat, Email, InfoCircle, QR } from '@wix/wix-ui-icons-common';

const methodToIconMap: { [key in BackupMethods]: JSX.Element } = {
  [BackupMethods.TOTP]: <QR />,
  [BackupMethods.SMS]: <Chat />,
  [BackupMethods.EMAIL]: <Email />,
};

const VerificationMethodIcon = ({ method }: { method: BackupMethods }) => {
  return methodToIconMap[method] || null;
};

export const BackupMethodStep: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: { ownerAppStore: store },
      },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  return (
    <Box padding="18px 24px 18px 24px" height={135}>
      <Box direction="vertical" gap={3}>
        <Box direction="horizontal">
          <Text size="medium" weight="normal">
            {t('wixAppEnforcement.addOwnerApp.wizard.backupMethod.label')}
          </Text>
        </Box>
        <RadioGroup
          dataHook={dataHooks.ownerAppModal.options}
          value={store.selectedBackupMethod}
          onChange={(type?: number | string) =>
            store.onSelectBackupMethod(type)
          }
        >
          {Object.values(BackupMethods)
            .filter((key) => {
              return (
                !store.premiumUsers2FaEnforcementStore.multiFactorAuth.Email
                  .isDomainRestricted || key !== BackupMethods.EMAIL
              );
            })
            .map((type, index) => (
              <RadioGroup.Radio key={type} value={index}>
                <Box direction="horizontal" gap="6px">
                  <VerificationMethodIcon method={type as BackupMethods} />
                  <Text size="medium" weight="thin">
                    {t(`addOwnerApp.wizard.backupMethod.${type}`)}
                  </Text>
                  {type === BackupMethods.TOTP && (
                    <Tooltip
                      maxWidth="289px"
                      placement="top"
                      content={t(
                        'premium.users.2fa.enforcement.authenticator.app.tooltip',
                      )}
                    >
                      <InfoCircle
                        style={{ color: '#116dff', verticalAlign: 'middle' }}
                        height={18}
                        width={18}
                      />
                    </Tooltip>
                  )}
                </Box>
              </RadioGroup.Radio>
            ))}
        </RadioGroup>
      </Box>
    </Box>
  );
});
