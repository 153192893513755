import { action, makeObservable, when, reaction } from 'mobx';
import { RootStore } from './root';
import { SocialProviderLoginStore } from './socialProviderLogin';
import logo from '../../images/apple-logo.svg?url';

interface AppleService {
  init: Function;
  renderButton: Function;
  signIn: Function;
}

export class AppleLoginStore extends SocialProviderLoginStore<LoginToWixAppleParams> {
  private appleService: AppleService;
  providerId: string;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.useNewSdk = this.rootStore.experiments?.enabled(
      'specs.ident.UseNewAppleSdkReactLoginApp',
    );
    this.providerId = 'apple';
    this.hasError = this.rootStore.experiments?.enabled(
      'specs.ident.ShowAppleErrorMsg',
    );

    this.customIconLogoUrl =
      'https://wixmp-7ef3383b5fd80a9f5a5cc686.wixmp.com/logos/apple-logo-white.svg';
    this.customButtonLogoUrl = logo;
    this.init();
    this.reactToSocialError();
    makeObservable(this, {
      onLogin: action,
      init: action,
    });
  }

  onClickCustomLoginButton(): void {
    this.socialButtonClicked();
    this.appleService
      .signIn()
      .then((response: AppleAuthResult) => this.onLogin(response))
      .catch(() => this.handleError({ showErrorStatus: false }));
  }

  protected reactToSocialError() {
    reaction(
      () =>
        this.rootStore.googleLoginStore?.hasError ||
        this.rootStore.facebookLoginStore?.hasError,
      (newError) => {
        if (newError) {
          this.hasError = false;
        }
      },
    );
  }

  async init() {
    when(
      () => window.AppleID?.auth,
      () => {
        this.isInitialized = true;
        this.appleService = window.AppleID.auth;
        if (this.useNewSdk) {
          // Listen for authorization success.
          document.addEventListener('AppleIDSignInOnSuccess', (event: any) =>
            this.onLogin(event?.detail),
          );

          // Listen for authorization failures.
          document.addEventListener('AppleIDSignInOnFailure', (event: any) =>
            this.handleError({
              error: event?.detail?.error,
              showErrorStatus: false,
            }),
          );
        }
      },
    );
  }

  initializeSdk() {
    this.useNewSdk && this.appleService?.renderButton();
  }

  async onLogin(authResult: AppleAuthResult) {
    const { user, authorization } = authResult;

    if (!authorization.id_token) {
      this.handleError();
      return;
    }

    await this.loginToWix({
      identityToken: authorization.id_token,
      ...(user?.name?.firstName ? { firstName: user?.name?.firstName } : {}),
      ...(user?.name?.lastName ? { lastName: user?.name?.lastName } : {}),
      ...(user?.email ? { email: user?.email } : {}),
    });
  }
}
