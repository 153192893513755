import React, { FC, useContext } from 'react';
import { CustomModalLayout, Stepper, Divider, Box } from '@wix/design-system';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { AppContextProvider } from '../../../AppLoader';
import { OwnerAppStore } from '../../../../stores/premiumUsers2FaEnforcement/ownerApp/ownerApp';

import { dataHooks } from '../../../../stores/premiumUsers2FaEnforcement/dataHooks';
import { ChooseDeviceStep } from './SelectDeviceStep';
import { ConfirmDeviceStep } from './ConfirmDeviceStep';
import { BackupMethodStep } from './BackupMethodStep';
import s from '../modals.scss';
import {ApprovalChallengeStatus, Steps} from '../../../../stores/premiumUsers2FaEnforcement/ownerApp/types';
import { SuccessfulStep } from '../../OwnerApp2Fa/steps/SuccessfulStep';
import { BackTextButton } from './BackTextButton';
import { EnforcementModal } from '../EnforcementModal';

export const OwnerAppAddAuthWizard: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: { ownerAppStore: store },
      },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  return (
    <EnforcementModal>
      <CustomModalLayout
        title={t('addOwnerApp.wizard.title')}
        subtitle={t('addOwnerApp.wizard.subtitle')}
        removeContentPadding
        primaryButtonText={store.primaryButtonText}
        primaryButtonOnClick={
          store.activeStep === Steps.ConfirmationStep
            ? undefined
            : store.nextStep
        }
        sideActions={
          <BackTextButton goBack={store.onBackToChooseVerificationMethod} />
        }
        width={600}
        className={s.enforcementOwnerAppModal}
        dataHook={dataHooks.ownerAppModal.main}
      >
        <Box padding="0 SP3 SP2">
          <Stepper
            dataHook={dataHooks.ownerAppModal.steps}
            type="text"
            onClick={store.onStepClick}
            activeStep={store.activeStep}
            steps={store.steps}
          />
        </Box>
        <Divider />
        <Box padding="SP6 SP3 SP7">
          <CurrentStep store={store} />
        </Box>
      </CustomModalLayout>
    </EnforcementModal>
  );
});

const stepToComponentMap: {
  [key in Steps]?:
    | JSX.Element
    | ((authStatus: ApprovalChallengeStatus) => JSX.Element);
} = {
  [Steps.DeviceSelectionStep]: <ChooseDeviceStep />,
  [Steps.ConfirmationStep]: (authStatus) =>
    authStatus === ApprovalChallengeStatus.PENDING_APPROVAL ||
    authStatus === ApprovalChallengeStatus.APPROVED ? (
      <ConfirmDeviceStep.Pending />
    ) : (
      <ConfirmDeviceStep.Error />
    ),
  [Steps.BackupMethodStep]: <BackupMethodStep />,
  [Steps.SuccessfulStep]: <SuccessfulStep />,
};

const CurrentStep: FC<{ store: OwnerAppStore }> = observer(({ store }) => {
  const { activeStep, authStatus } = store;
  const stepComponent = stepToComponentMap[activeStep];

  return typeof stepComponent === 'function'
    ? stepComponent(authStatus)
    : stepComponent || null;
});
