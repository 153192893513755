import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading } from '@wix/design-system';
import { AppContextProvider } from '../../AppLoader';
import PostLoginModal from '../PostLoginModal';
import { ChooseVerificationMethodContent } from './ChooseVerificationMethodContent';
import { HelpCircleTooltip } from "../WixAppEnforcement/HelpCircleTooltip";

export const ChooseVerificationMethodModal: React.FC = observer(() => {
  const { t } = useTranslation();
  const {
    rootStore: {
      postLoginStore: { premiumUsers2FaEnforcementStore: store },
    },
  } = useContext(AppContextProvider);
  const showHelpCircle = store.messagePrefix && !store.twoFASettings.ownerAppMethod;

  return (
    <PostLoginModal
      minHeight="100px"
      removeContentPadding={true}
      width={546}
      height={store.modalHeight}
      title={
        <Box style={{position: 'relative'}}>
          <Heading size={"medium"}>{t(`${store.messagePrefix}premium.users.2fa.enforcement.title`)}</Heading>
          {showHelpCircle &&
          <Box style={{position: "absolute", right: "-11px", top: "-2px"}}>
            <HelpCircleTooltip/>
          </Box>}
        </Box>}
      subtitle={store.subtitle}
      primaryButtonText={store.primaryButtonText}
      primaryButtonAction={store.primaryButtonAction}
      secondaryButtonText={store.secondaryButtonText}
      secondaryButtonAction={store.secondaryButtonAction}
      footerText={"premium.users.2fa.enforcement.footer"}
      footerLink={`https://support.wix.com/${store.postLoginStore.rootStore.language.locale}/article/secure-your-account-with-2-step-verification`}
      hideFooter={store.isWixAppEnforcement}
      footerWeight="normal"
      disableLogoClick={true}
      marginTop={10}
    >
      <Box>
        <ChooseVerificationMethodContent/>
      </Box>
    </PostLoginModal>
  );
});
