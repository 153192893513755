import AccountRecoveryCodeDialog from '../AccountRecoveryCodeDialog';
import AccountVerification from '../AccountVerification/AccountVerification';
import EmailAlreadyExist from '../EmailAlreadyExist';
import Login from '../Login/Login';
import ProtectedRoute from '../ProtectedRoute';
import React, { useContext } from 'react';
import SelectAccount from '../SelectAccount/SelectAccount';
import StepUpDialog from '../StepUpDialog';
import TwoFactorAuthDialog from '../TwoFactorAuthDialog';
import { AppContextProvider } from '../AppLoader';
import { dataHooks } from '../../dataHooks';
import { DEFAULT_ROUTE, ROUTES } from '../../routes';
import { EmailStepDialog } from '../EmailStep/EmailStep';
import { EnterEmailForSocial } from '../EnterEmailForSocial';
import { Loader, Modal } from '@wix/design-system';
import { observer } from 'mobx-react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ResetPassword } from '../ResetPassword/ResetPassword';
import { ResetPasswordConfirmation } from '../ResetPasswordConfirmation/ResetPasswordConfirmation';
import { ResetPasswordInvalid } from '../ResetPasswordInvalid/ResetPasswordInvalid';
import { SsoEnterEmail } from '../SsoEnterEmail';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ApproveAndContinue } from '../ApproveAndContinue/ApproveAndContinue';
import PostLoginRouter from '../PostLogin/PostLoginRouter';
import s from './App.scss';
import { Signup } from '../Signup';
import { SocialOnlyDialog } from '../SocialOnlyDialog/SocialOnlyDialog';
import { LoadingDialog } from '../LoadingDialog/LoadingDialog';
import { DeviceLogin } from '../DeviceLogin/DeviceLogin';
import { ConnectionSuccessful } from '../ConnectionSuccessful/ConnectionSuccessful';
import classNames from 'classnames';
import { DisconnectSuccessful } from '../DisconnectSuccessful/DisconnectSuccessful';
import SelectWorkspace from '../SelectWorkspace/SelectWorkspace';
import { BlockedAccount } from '../BlockedAccount/BlockedAccount';

interface AppProps extends WithTranslation {}

const App: React.FC<AppProps> = observer(() => {
  const {
    rootStore: {
      isLoading,
      twoFactorAuthStore,
      accountRecoveryCodeStore,
      stepUpAuthStore,
      resetPasswordStore,
      resetPasswordInvalidStore,
      selectAccountStore,
      selectWorkspaceStore,
      accountVerificationStore,
      postSocialLoginEnterEmailStore,
      socialAuthStore,
      loginStore,
      ssoEmailAlreadyExistStore,
      displayStore: { preset, isFullScreenMode, isIframe },
      deviceLoginStore,
      onClickRootElement,
    },
  } = useContext(AppContextProvider);
  const { search } = useLocation();

  return (
    <div
      data-hook={dataHooks.app.root}
      // TODO - Remove when finish investigating new login failure
      onClick={(e) => onClickRootElement(e)}
      className={classNames(s.root, {
        [s.iframe]: isIframe,
        [s.modalMode]: !isFullScreenMode,
      })}
      style={{
        background: preset.background as any,
        height: 'auto',
        minHeight: '100vh',
      }}
    >
      <Modal isOpen={isLoading}>
        <Loader />
      </Modal>
      <Switch>
        <Route path={ROUTES.SSO_ENTER_EMAIL} exact>
          <SsoEnterEmail />
        </Route>
        <Route path={ROUTES.SSO_MAIN} exact>
          <ApproveAndContinue />
        </Route>
        <Route path={ROUTES.SSO_EMAIL_ALREADY_EXIST} exact>
          <ProtectedRoute condition={ssoEmailAlreadyExistStore.enableAccess}>
            <EmailAlreadyExist />
          </ProtectedRoute>
        </Route>
        <Route path={ROUTES.SELECT_ACCOUNT} exact>
          <ProtectedRoute condition={selectAccountStore.enableAccess()}>
            <SelectAccount />
          </ProtectedRoute>
        </Route>
        <Route path={ROUTES.SELECT_WORKSPACE} exact>
          <ProtectedRoute condition={selectWorkspaceStore.enableAccess()}>
            <SelectWorkspace />
          </ProtectedRoute>
        </Route>
        <Route path={ROUTES.EMAIL_STEP} exact>
          <EmailStepDialog />
        </Route>
        <Route path={ROUTES.LOGIN_PASSWORD} exact>
          <Login />
        </Route>
        <Route path={ROUTES.SOCIAL_ONLY} exact>
          <ProtectedRoute condition={socialAuthStore.enableAccess}>
            <SocialOnlyDialog />
          </ProtectedRoute>
        </Route>
        {!loginStore.shouldBlockEditorXSignup() && (
          <Route path={ROUTES.SIGNUP_PASSWORD} exact>
            <Signup />
          </Route>
        )}
        <Route path={ROUTES.RESET_PASSWORD_CONFIRMATION} exact>
          <ProtectedRoute
            condition={resetPasswordStore.enableAccessConfirmation}
          >
            <ResetPasswordConfirmation />
          </ProtectedRoute>
        </Route>
        <Route path={ROUTES.RESET_PASSWORD} exact>
          <ProtectedRoute condition={resetPasswordStore.enableAccess}>
            <ResetPassword />
          </ProtectedRoute>
        </Route>
        <Route path={ROUTES.RESET_PASSWORD_INVALID} exact>
          <ProtectedRoute condition={resetPasswordInvalidStore.enableAccess}>
            <ResetPasswordInvalid />
          </ProtectedRoute>
        </Route>
        <Route path={ROUTES.TWO_FACTOR_AUTH} exact>
          <ProtectedRoute condition={twoFactorAuthStore.enableAccess()}>
            <TwoFactorAuthDialog />
          </ProtectedRoute>
        </Route>
        <Route path={ROUTES.RECOVER_ACCOUNT} exact>
          <ProtectedRoute condition={accountRecoveryCodeStore.enableAccess()}>
            <AccountRecoveryCodeDialog />
          </ProtectedRoute>
        </Route>
        <Route path={ROUTES.STEP_UP} exact>
        {/* Used for step up action called from other verticals that renders it in an iframe */}
          <ProtectedRoute condition={stepUpAuthStore.enableAccess()}>
            <StepUpDialog />
          </ProtectedRoute>
        </Route>
        <Route path={ROUTES.ACCOUNT_VERIFICATION} exact>
          <ProtectedRoute condition={accountVerificationStore.enableAccess()}>
            <AccountVerification />
          </ProtectedRoute>
        </Route>
        <Route path={ROUTES.POST_LOGIN}>
          <PostLoginRouter />
        </Route>
        <Route path={[ROUTES.GOOGLE_LOGIN, ROUTES.STEP_UP_INITIATION]}>
          <LoadingDialog />
        </Route>
        <Route path={ROUTES.SOCIAL_ENTER_EMAIL} exact>
          <ProtectedRoute
            condition={postSocialLoginEnterEmailStore.enableAccess}
          >
            <EnterEmailForSocial />
          </ProtectedRoute>
        </Route>
        <Route path={ROUTES.DEVICE_LOGIN} exact>
          <ProtectedRoute
            condition={deviceLoginStore.enableAccess()}
            defaultRoute={
              ROUTES.LOGIN_PASSWORD +
              `?redirectTo=${
                window.location.origin + '/login' + ROUTES.DEVICE_LOGIN
              }`
            }
          >
            <DeviceLogin />
          </ProtectedRoute>
        </Route>
        <Route path={ROUTES.CONNECTION_SUCCESSFUL} exact>
          <ConnectionSuccessful />
        </Route>
        <Route path={ROUTES.DISCONNECT_SUCCESSFUL} exact>
          <DisconnectSuccessful />
        </Route>
        <Route path={ROUTES.BLOCKED_ACCOUNT} exact>
          <BlockedAccount />
        </Route>
        <Redirect to={`${DEFAULT_ROUTE}${search}`} />
      </Switch>
    </div>
  );
});

export default withTranslation()(App);
