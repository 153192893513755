import { HttpClient } from '@wix/http-client';
import { isNumber } from 'lodash';
import {
  ACCOUNT_SERVER_API_PATH,
  ACCOUNT_SETTINGS_API_PATH,
  USERS_API_PATH,
  OWNER_AUTHENTICATOR_PATH,
} from '../utils/constants';
import { ERROR_CODES } from '../utils/errorHandler';
import { AccountsResponse } from '@wix/ambassador-account-server/types';
import { setMyPassword } from '@wix/ambassador-identity-users-v1-user/http';
import { SetMyPasswordResponse } from '@wix/ambassador-identity-users-v1-user/types';
import { AuthenticatorAppData } from '../stores/premiumUsers2FaEnforcement/authApp/types';

export type AccountSettingsApi = AccountSettingsHttpApi;
export class AccountSettingsHttpApi {
  private readonly httpClient = new HttpClient();

  constructor() {
    this.handleError = this.handleError.bind(this);
  }

  public OwnerApp = {
    enable: async (verificationId: string, deviceId?: string) => {
      const result = await this.httpClient.post(
        `/${ACCOUNT_SETTINGS_API_PATH}/2fa/push/enable`,
        {
          verificationId,
          deviceId,
        },
      );
      return result.data;
    },
    getStatus: async (verificationId: string) => {
      const result = await this.httpClient.get(
        `/${OWNER_AUTHENTICATOR_PATH}/get-challenge?verificationId=${verificationId}`,
      );
      return result.data;
    },
  };
  public AuthApp = {
    enable: async (password: string, sharedSecretKey: string, code: string) => {
      const result = await this.httpClient.post(
        `/${ACCOUNT_SETTINGS_API_PATH}/2fa/totp/enable`,
        {
          password,
          sharedSecretKey,
          code,
        },
      );
      return result.data;
    },
  };
  public Phone = {
    enable: async (
      phone: PhoneDTO,
      code: string,
      verificationId: string,
      password?: string,
    ) => {
      const result = await this.httpClient.post(
        `/${ACCOUNT_SETTINGS_API_PATH}/2fa/phone/enable`,
        {
          phone,
          code,
          verificationId,
          password,
        },
      );
      return result.data;
    },
  };
  public Email = {
    enable: async (
      email: string,
      code?: string,
      verificationId?: string,
      password?: string,
    ) => {
      const result = await this.httpClient.post(
        `/${ACCOUNT_SETTINGS_API_PATH}/2fa/email/enable`,
        {
          email,
          code,
          verificationId,
          password,
        },
      );
      return result.data;
    },
  };

  public async getUserDetails(): Promise<UserDetailsDTO> {
    const res = await this.httpClient.get(
      `/${ACCOUNT_SETTINGS_API_PATH}/users`,
    );
    return res.data;
  }

  public async updateEmail({
    confirmationCode,
    verificationId,
  }: {
    confirmationCode: string;
    verificationId: string;
  }): Promise<boolean> {
    const params: ConfirmEmailCodeDTO = {
      verificationCode: confirmationCode,
      verificationId,
    };

    const result = await this.httpClient.put(
      `${USERS_API_PATH}/users/email`,
      params,
    );
    return result.status === 200;
  }

  public async verifyEmailCode({
    verificationCode,
    verificationId,
  }: {
    verificationCode: string;
    verificationId: string;
  }): Promise<boolean> {
    const params: ConfirmEmailCodeDTO = {
      verificationCode,
      verificationId,
    };

    const result = await this.httpClient.post(
      `/${ACCOUNT_SETTINGS_API_PATH}/verify-email-code`,
      params,
    );
    return result.status === 200;
  }

  public async getUserPhone(): Promise<UserPhoneDTO> {
    const result = await this.httpClient.get(
      `/${ACCOUNT_SETTINGS_API_PATH}/phones`,
    );
    return result.data;
  }

  public sendVerificationCode({
    email,
    phone,
    deviceId,
    incognito,
    isActionTagRequired,
  }: {
    email?: string;
    phone?: PhoneDTO;
    deviceId?: string;
    incognito?: boolean;
    isActionTagRequired?: boolean;
  }): Promise<any> {
    return this.httpClient.post(
      `/${ACCOUNT_SETTINGS_API_PATH}/send-verification-code`,
      {
        phone: phone
          ? phone.internationalNumber ??
            `${phone.countryCode}${phone.nationalNumber}`
          : undefined,
        email,
        deviceId,
        incognito,
        isActionTagRequired,
      },
    );
  }

  public async verifyAndEnableRecoveryPhone(
    phone: PhoneDTO,
    code: string,
    verificationId: string,
  ) {
    const result = await this.httpClient.post(
      `/${ACCOUNT_SETTINGS_API_PATH}/recovery-phone/enable`,
      {
        phone,
        code,
        verificationId,
      },
    );
    return result.data;
  }

  public async verifyAndEnablePhoneTwoFA(
    phone: PhoneDTO,
    code: string,
    verificationId: string,
  ) {
    const result = await this.httpClient.post(
      `/${ACCOUNT_SETTINGS_API_PATH}/2fa/phone/enable`,
      {
        phone,
        code,
        verificationId,
      },
    );
    return result.data;
  }

  public async getTwoFASettings(): Promise<TwoFASettingsDTO> {
    const result = await this.httpClient.get(
      `/${ACCOUNT_SETTINGS_API_PATH}/2fa`,
    );
    return result.data;
  }

  public async updatePassword(
    password: string,
    newPassword: string,
  ): Promise<SetMyPasswordResponse> {
    const result = await this.httpClient.request(
      setMyPassword({ newPassword, currentPassword: password }),
    );
    return result.data;
  }
  public async checkIfEmailExist(email: string) {
    const result = await this.httpClient.get(
      `/${ACCOUNT_SETTINGS_API_PATH}/is-email-exist?email=${email}`,
    );
    return result.data;
  }

  public async fetchUserWorkspaces(): Promise<AccountsResponse> {
    const result = await this.httpClient.get(
      `/${ACCOUNT_SERVER_API_PATH}users/my_accounts?paging.limit=2`,
    );
    return result.data;
  }

  public async startAuthenticatorAppMethod(
    email: string,
  ): Promise<AuthenticatorAppData> {
    const result = await this.httpClient.post(
      `/${ACCOUNT_SETTINGS_API_PATH}/authenticator-app/start`,
      {
        email,
      },
    );
    return result.data;
  }

  public forgotPass(email: any) {
    return this.httpClient.post(`/${ACCOUNT_SETTINGS_API_PATH}/resetpassword`, {
      email,
    });
  }

  public handleError(response: any) {
    const errorCode =
      response?.response?.data?.errorCode ||
      response?.data?.errorCode ||
      response?.data?.reason ||
      response?.reason;
    const errorReason = this.errorHandling(errorCode);

    return { success: false, errorCode, errorReason };
  }

  public errorHandling(
    errorCode: number | string,
    defaultMsg = 'ops_something_went_wrong',
  ) {
    const errorCodeStr =
      errorCode && isNumber(errorCode) ? errorCode.toString() : errorCode;
    switch (errorCodeStr) {
      case ERROR_CODES.PASSWORD_INCORRECT:
      case ERROR_CODES.IAM_PASSWORD_INCORRECT:
        return 'pass_is_incorrect';
      case ERROR_CODES.EMAIL_ALREADY_EXISTS:
        return 'email_already_exists';
      case ERROR_CODES.USERNAME_ALREADY_EXISTS:
        return 'username_already_exists';
      case ERROR_CODES.INVALID_PHONE_NUMBER:
        return 'validation.phone.invalid';
      case -80000:
        return 'validation.phone.throttle';
      case 'NOT_FOUND':
        return 'validation.phone.codeExpired';
      case 'BAD_CODE':
      case '8005':
        return 'post.login.bad.code';
      case 'SEND_CODE_ERROR':
      default:
        return defaultMsg;
    }
  }
}
