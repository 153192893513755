import React, { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import s from './ApproveAndContinue.scss';
import { DialogHeader } from '../DialogHeader/DialogHeader';
import { Box } from '@wix/design-system';
import { StatusAlertFilledSmall } from '@wix/wix-ui-icons-common';
import { AppContextProvider } from '../AppLoader';
import { ApproveAndContinueModes } from './approveAndContinueConstants';
import { DialogFormContainer } from '../DialogContainer';
import { SubmitButton } from '../SubmitButton';
import { dataHooks } from '../../dataHooks';
import { ThemedHeading, ThemedText } from '../ThemedComponents';
import { ERROR_CODES } from '../../utils/errorHandler';
import { ROUTES } from '../../routes';

export const ApproveAndContinue: React.FC = observer(() => {
  const {
    rootStore: { approveAndContinueStore, navigationStore },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  const {
    titleKey,
    contentKey,
    transParams,
    submitButtonKey,
    showDescriptionSection,
    secondaryLogoUrl,
    errorCode,
    errorKey,
    showErrorSection,
    titleDataHook,
    submitButtonDataHook,
    shouldHideContent,
  } = approveAndContinueStore;

  return (
    <Box direction="vertical" width="100%" align="center">
      <DialogFormContainer
        formClasses={[s.approveAndContinueContainer]}
        mainHeaderProps={{ secondaryLogoUrl }}
        dataHook={dataHooks.approveAndContinueDialog.container}
      >
        <DialogHeader className={s.title} dataHook={titleDataHook}>
          <DialogHeader.Title flow='secondaryFlow'>
            <Trans i18nKey={titleKey} values={transParams}>
              <ThemedHeading>Log in with your ssoAccountName account</ThemedHeading>
            </Trans>
          </DialogHeader.Title>
          {!shouldHideContent && (
            <DialogHeader.SubTitleSmall>
              {t(contentKey, transParams)}
            </DialogHeader.SubTitleSmall>
          )}
        </DialogHeader>
        <Box
          direction="vertical"
          gap="35px"
          className={s.approveAndContinueContent}
        >
          {!shouldHideContent && (
            <>
              <SubmitButton
              dataHook={submitButtonDataHook}
                skin="standard"
                onClick={() => approveAndContinueStore.submit()}
                disabled={showErrorSection}
              >
                {t(submitButtonKey)}
              </SubmitButton>
              {showDescriptionSection && (
                <div className={s.approveAndContinueDescription}>
                  <DescriptionTemplate />
                </div>
              )}
            </>
          )}
          {showErrorSection && (
            <Box className={s.approveAndContinueErrorSection}>
              <ThemedText skin="error" className={s.errorText}>
                <StatusAlertFilledSmall className={s.statusIcon} />
                {t(errorKey, { ...transParams, errorCode })}
              </ThemedText>
            </Box>
          )}
        </Box>
      </DialogFormContainer>
    </Box>
  );
});

export const DescriptionTemplate: React.FC = observer(() => {
  const { t } = useTranslation();
  const {
    rootStore: {
      approveAndContinueStore: { descriptionKey, transParams, mode },
    },
  } = useContext(AppContextProvider);
  switch (mode) {
    case ApproveAndContinueModes.SSO_OTHER_ACCOUNT_LOGGED_IN:
      return (
        <Trans i18nKey={descriptionKey} values={transParams}>
          <ThemedText secondary>
            You're currently logged in to Wix with{' '}
          </ThemedText>
          <ThemedText weight="bold" secondary>
            email
          </ThemedText>
          <ThemedText secondary>
            By continuing, you'll be logged out of this account.
          </ThemedText>
        </Trans>
      );
    case ApproveAndContinueModes.FORGOT_PASSWORD:
      return (
        <Trans i18nKey={descriptionKey} values={transParams}>
          <ThemedText secondary>
            If you'd like to reset your Yell UK password{' '}
          </ThemedText>
        </Trans>
      );
    case ApproveAndContinueModes.FORGOT_EMAIL:
      return (
        <Trans i18nKey={descriptionKey} values={transParams}>
          <ThemedText secondary>
            If you don't remember your email address{' '}
          </ThemedText>
        </Trans>
      );
    default:
      return (
        <ThemedText secondary>{t(descriptionKey, transParams)}</ThemedText>
      );
  }
});
