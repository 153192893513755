import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import {Box, Heading, Image, Text} from '@wix/design-system';
import { AppContextProvider } from '../../AppLoader';
import shieldKey from '../../../assets/images/shieldKey.svg?url';
import { useTranslation } from 'react-i18next';

export const SuccessfulStep: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: { ownerAppStore: store },
      },
    },
  } = useContext(AppContextProvider);
  useEffect(() => {
    store.enable();
    store.premiumUsers2FaEnforcementStore.fetchTwoFASettings();
  }, [store]);
  const { t } = useTranslation();
  return (
    <Box
      paddingTop={4}
      paddingRight={4}
      paddingBottom={2}
      paddingLeft={4}
      gap={4}
    >
      <Image src={shieldKey} width={120} height={120} transparent={true} />
      <Box direction="vertical" gap={2} width={408} height={120}>
        <Box width={408} direction="vertical">
          <Heading size="medium" >
            {t('wixAppEnforcement.addOwnerApp.wizard.successful.title')}
          </Heading>
        </Box>
        <Box width={408} height={48} maxHeight={48} direction="vertical">
          <Text size="medium">
            {t('wixAppEnforcement.addOwnerApp.wizard.successful.subtitle')}
            <span style={{fontWeight: 500}}>{store.email}</span>
          </Text>
        </Box>
      </Box>
    </Box>
  );
});
