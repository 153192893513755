import { CustomFontSize } from '../components/DialogHeader/DialogHeader';
import { ButtonSkin, Size } from '@wix/design-system';

type Skin = 'light' | 'dark' | 'standard'; // 'light' should be used on dark backgrounds
export type LogoPosition = 'left' | 'center';

type ButtonDesign = {
  skin: ButtonSkin;
  textKey?: string;
  color?: string;
  fullWidth?: boolean;
};

type Title = {
  key: string;
  font?: string;
  size?: Size;
};

type Container = {
  padding: string;
  borderRadius: number;
  boxShadow: string;
};

type AuthDialog = {
  title?: Title;
  subtitle?: Title;
  flows?: {
    id: string;
    title: Title;
    subtitle?: Title;
  }[];
  primaryButtonDesign?: ButtonDesign;
  secondaryButtonDesign?: ButtonDesign;
};

type LoginAuthDialog = AuthDialog & {
  alreadyHaveAccount?: {
    title?: Title;
    subtitle?: Title;
  };
};

export const SocialProviders = {
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
  APPLE: 'apple',
};

export const PRESETS = {
  DAYFUL: 'dayful',
  EDITORX: 'editorx',
  HOPP: 'hopp',
  EDUCATION_STUDENT: 'EDUCATION_STUDENT',
  EDUCATION_TEACHER: 'EDUCATION_TEACHER',
  CODUX: 'codux', // New branding for WixComponentsStudio (WCS)
  LOGO_MAKER: 'logo_maker',
  BUSINESS_CARD_MAKER: 'bcm',
  WIXEL: 'wixel',
};

export type ViewPreset = Partial<{
  id: string; // Default - preset key
  nameKey: string; // Default - 'presets.names.wix' = Wix
  skin: Skin; // Default - standard
  shouldDisablePostLoginSuggestions: boolean; // Default - false
  container: {
    layout: 'vertical' | 'horizontal'; // Default - horizontal
    desktop: Container;
    mobile: Container;
  };
  background: string;
  login: LoginAuthDialog;
  signup: AuthDialog;
  landingPage: AuthDialog; // Main landing page (email step)
  logo: Partial<{
    // Default - wix logo
    src: string;
    position: LogoPosition; // Default - 'left'
    height: string; // Default - 24px
    mobile: {
      height: string; // Default - 20px
    };
    alt: string; // Default - 'Wix'
    url: string; // Default - www.wix.com
  }>;
  policies: {
    policyKey?: string; // Default - 'policies_section.signup_content'
    termsOfUseUrl?: string; // Default - wix terms of use
    privacyPolicyUrl?: string; // Default - wix privacy policy
  };
  buttonDesign?: ButtonDesign;
  // Social providers separated with comma (for example - 'google,facebook')
  // Default - all ('google,facebook','apple')
  socialProviders: string;
  socialProvidersNewDesign: boolean;
  titleCustomFontSize: {
    authPage: {
      desktop: CustomFontSize;
      mobile: CustomFontSize;
    };
    secondaryFlow: {
      desktop: CustomFontSize;
      mobile: CustomFontSize;
    };
  };
  cssVariables: Record<string, string>;
}>;

export const skinToColor = {};
export const DEFAULT_LOGO_HEIGHT = '26px';

export const ViewPresets: {
  [key: string]: ViewPreset;
} = {
  [PRESETS.DAYFUL]: {
    nameKey: 'presets.names.dayful',
    landingPage: {
      title: {
        key: 'emailStep.dayful.title',
      },
    },
    signup: {
      title: {
        key: 'signup.title',
      },
      subtitle: {
        key: 'dayful_subtitle',
      },
    },
    logo: {
      src: 'https://wixmp-7ef3383b5fd80a9f5a5cc686.wixmp.com/logos/dayful-logo.png',
      height: '34px',
      url: 'https://www.dayful.com/',
    },
  },
  [PRESETS.HOPP]: {
    nameKey: 'presets.names.hopp',
    landingPage: {
      title: {
        key: 'emailStep.hopp.title',
      },
    },
    signup: {
      title: {
        key: 'signup.title',
      },
      subtitle: {
        key: 'hopp_subtitle',
      },
    },
    logo: {
      src: 'https://wixmp-7ef3383b5fd80a9f5a5cc686.wixmp.com/Hopp%20by%20wix%20logo%20(1).svg',
      height: '22px',
      url: 'https://www.hopp.co/',
    },
  },
  [PRESETS.EDUCATION_STUDENT]: {
    id: PRESETS.EDUCATION_STUDENT,
    nameKey: 'presets.names.education',
    landingPage: {
      title: {
        key: 'emailStep.education.student.title',
      },
    },
    login: {
      subtitle: {
        key: 'login.education.subtitle',
      },
    },
    signup: {
      title: {
        key: 'signup.education.student.title',
      },
    },
    logo: {
      src: 'https://images-wixmp-7ef3383b5fd80a9f5a5cc686.wixmp.com/642436ff-70f5-4ebc-8fea-47fab46f7f73/1693247680916',
      url: 'https://www.wix.com/education',
    },
    policies: {
      termsOfUseUrl:
        'https://www.wix.com/tomorrow/classroom/platform/terms-of-use',
      privacyPolicyUrl:
        'https://www.wix.com/tomorrow/classroom/platform/privacy-policy',
    },
    socialProviders: SocialProviders.GOOGLE,
  },
  [PRESETS.EDUCATION_TEACHER]: {
    id: PRESETS.EDUCATION_TEACHER,
    nameKey: 'presets.names.education',
    landingPage: {
      title: {
        key: 'emailStep.education.teacher.title',
      },
    },
    login: {
      subtitle: {
        key: 'login.education.subtitle',
      },
    },
    signup: {
      title: {
        key: 'signup.education.teacher.title',
      },
    },
    logo: {
      src: 'https://images-wixmp-7ef3383b5fd80a9f5a5cc686.wixmp.com/642436ff-70f5-4ebc-8fea-47fab46f7f73/1693247680916',
      url: 'https://www.wix.com/education',
    },
    policies: {
      termsOfUseUrl:
        'https://www.wix.com/tomorrow/classroom/platform/terms-of-use',
      privacyPolicyUrl:
        'https://www.wix.com/tomorrow/classroom/platform/privacy-policy',
    },
    socialProviders: SocialProviders.GOOGLE,
  },
  [PRESETS.CODUX]: {
    logo: {
      src: 'https://wixplosives.github.io/codux-assets-storage/login/codux-logo.svg',
      position: 'center',
      height: '100px',
      mobile: {
        height: '80px',
      },
      url: 'https://www.codux.com/',
    },
    signup: {
      title: {
        key: 'signup.title',
      },
    },
    policies: {
      termsOfUseUrl: 'https://www.wix.com/about/terms-of-use',
      privacyPolicyUrl: 'https://www.wix.com/about/privacy',
    },
  },
  [PRESETS.EDITORX]: {
    nameKey: 'presets.names.editorx',
    landingPage: {
      title: {
        key: 'emailStep.editorx.title',
      },
    },
    signup: {
      title: {
        key: 'signup.title',
      },
    },
    logo: {
      src: 'https://wixmp-7ef3383b5fd80a9f5a5cc686.wixmp.com/logos/EditorX_logo_36x168_black.svg',
      url: 'https://www.editorx.com/',
    },
  },
  [PRESETS.LOGO_MAKER]: {
    nameKey: 'presets.names.logo_maker',
    logo: {
      url: 'https://www.wix.com/logo/maker/',
    },
    landingPage: {
      title: {
        key: 'emailStep.logo_maker.title',
      },
    },
    login: {
      title: {
        key: 'login.logo_maker.title',
      },
    },
    signup: {
      title: {
        key: 'signup.logo_maker.title',
      },
    },
  },
  [PRESETS.BUSINESS_CARD_MAKER]: {
    nameKey: 'presets.names.business_card_maker',
    logo: {
      url: 'https://www.wix.com/tools/business-card-maker/',
    },
    login: {
      title: {
        key: 'login.business_card_maker.title',
      },
    },
    signup: {
      title: {
        key: 'signup.business_card_maker.title',
      },
    },
  },

  [PRESETS.WIXEL]: {
    id: PRESETS.WIXEL,
    shouldDisablePostLoginSuggestions: true,
    nameKey: 'presets.names.wixel',
    container: {
      layout: 'vertical',
      desktop: {
        padding: '54px 0 66px 0',
        borderRadius: 48,
        boxShadow: '0px 5px 54px 0px #10158547',
      },
      mobile: {
        padding: '36px 0 54px 0',
        borderRadius: 16,
        boxShadow: '0px 0px 45px 0px #1015850D',
      },
    },
    logo: {
      src: 'https://wixmp-7ef3383b5fd80a9f5a5cc686.wixmp.com/logos/wixel-logo.svg',
      url: 'https://www.wix.com/intro/wixel',
      height: '28px',
      alt: 'Wixel',
      mobile: {
        height: '24px',
      },
    },
    landingPage: {
      title: {
        key: 'emailStep.wixel.title',
      },
    },
    socialProvidersNewDesign: true,
    login: {
      title: {
        key: 'login.wixel.title',
      },
      subtitle: {
        key: 'login.wixel.subtitle',
      },
      primaryButtonDesign: {
        textKey: 'signup.wixel.primary_button',
        skin: 'dark',
        fullWidth: true,
      },
      alreadyHaveAccount: {
        title: {
          key: 'login.wixel.already_have_account',
        },
      },
    },
    signup: {
      title: {
        key: 'signup.wixel.title',
      },
      subtitle: {
        key: 'signup.wixel.subtitle',
      },
      primaryButtonDesign: {
        textKey: 'signup.wixel.primary_button',
        skin: 'dark',
        fullWidth: true,
      },
      flows: [
        {
          id: 'image',
          title: {
            key: 'signup.wixel.image_flow.title',
          },
          subtitle: {
            key: 'signup.wixel.image_flow.subtitle',
          },
        },
      ],
    },
    buttonDesign: {
      skin: 'dark',
      fullWidth: true,
    },
    cssVariables: {
      '--wds-loader-fill-loading': '#D6FFE1',
      '--wds-loader-fill': '#1AAC40',
    },
    background: `url("https://wixmp-7ef3383b5fd80a9f5a5cc686.wixmp.com/logos/bg-pattern.png") repeat 0 0 /200px, linear-gradient(#5AD87B, #C0D6FF)`,
    policies: {
      policyKey: 'policies_section.signup.wixel',
    },
  },
};
