import { makeAutoObservable } from 'mobx';
import {OwnerAppStore} from "../../../stores/premiumUsers2FaEnforcement/ownerApp/ownerApp";
import {ApprovalChallengeStatus} from "../../../stores/premiumUsers2FaEnforcement/ownerApp/types";

export class UiStore {
  constructor(private store: OwnerAppStore) {
    makeAutoObservable(this);
  }

  translate(s?: string) {
    return s ? this.store.premiumUsers2FaEnforcementStore.t(s) : s;
  }

  get title() {
    switch (this.store.authStatus) {
      case ApprovalChallengeStatus.EXPIRED:
        return this.translate('wixAppEnforcement.addOwnerApp.wizard.confirm.expired.heading')
      case ApprovalChallengeStatus.DECLINED:
        return this.translate('wixAppEnforcement.addOwnerApp.wizard.confirm.declined.heading')
      case ApprovalChallengeStatus.ERROR:
        return this.translate('wixAppEnforcement.addOwnerApp.wizard.confirm.error.heading')
      default:
        return "";
    }
  }

  get subtitle() {
    switch (this.store.authStatus) {
      case ApprovalChallengeStatus.EXPIRED:
        return this.translate('wixAppEnforcement.addOwnerApp.wizard.confirm.expired.message')
      case ApprovalChallengeStatus.DECLINED:
        return this.translate('wixAppEnforcement.addOwnerApp.wizard.confirm.declined.message')
      case ApprovalChallengeStatus.ERROR:
        return this.translate('wixAppEnforcement.addOwnerApp.wizard.confirm.error.message1')
      default:
        return "";
    }
  }

  get isError() {
    return this.store.authStatus === ApprovalChallengeStatus.ERROR;
  }
}
