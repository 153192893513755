import React, {useContext} from "react";
import {observer} from "mobx-react";
import {AppContextProvider} from "../../AppLoader";
import {useTranslation} from "react-i18next";
import {Box, SectionHelper} from "@wix/design-system";
import {InfoCircle} from "@wix/wix-ui-icons-common";

export const SendErrorSectionHelper: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: { ownerAppStore: store },
      },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  return (
    <Box
      position="absolute"
      top="24px"
      left="50%"
      width={360}
      height={54}
      transform="translateX(-50%)"
    >
      <SectionHelper
        appearance="danger"
        size="small"
        showPrefixIcon
        prefixIcon={<InfoCircle size={18}/>}
        showCloseButton
        onClose={() => store.showResendError = false}
      >
        {t(
          'wixAppEnforcement.addOwnerApp.wizard.error.resend',
        )}
      </SectionHelper>
    </Box>
  )});

