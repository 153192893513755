import { Box } from '@wix/design-system';
import { WorkspaceSelector } from '@wix/identity-fed-common';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { dataHooks } from '../../dataHooks';
import { AppContextProvider } from '../AppLoader';
import { BodyContainer } from '../AuthPage/AuthPage';
import { DialogFormContainer } from '../DialogContainer';
import s from './SelectWorkspace.scss';

interface SelectWorkspaceProps extends WithTranslation {}

const SelectWorkspace: React.FC<SelectWorkspaceProps> = observer(() => {
  const { rootStore } = useContext(AppContextProvider);
  const { selectWorkspaceStore, displayStore } = rootStore;
  const locale = rootStore.locale?.split('_')[0] || 'en';
  return (
    <Box direction="vertical" width="100%" align="center">
      <DialogFormContainer
        dataHook={dataHooks.selectWorkspaceDialog.container}
        contentClasses={[s.selectWorkspaceDialog]}
        mainHeaderProps={{
          backButtonCallback: selectWorkspaceStore.onBackClicked,
        }}
      >
        <BodyContainer>
          <WorkspaceSelector
            email={selectWorkspaceStore.email}
            isMobile={displayStore.isMobile}
            locale={locale}
          />
        </BodyContainer>
      </DialogFormContainer>
    </Box>
  );
});

export default withTranslation()(SelectWorkspace);
